import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class IgnoreItemFormAlert extends Component {
  state = {
    show: false,
    success: false,
    type: '',
  };

  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    
    if (error !== prevProps.error) {
      if (error.msg.message) {
        this.setState({ 
          show: true, 
          type: error.msg.message 
        });
      }
      if (error.msg.match_field) {
        this.setState({ 
          show: true, 
          type: error.msg.match_field 
        }, () => {
            this.props.onAlertChange('error_field');
        });
      }
      if (error.msg.match_type) {
        this.setState({ 
          show: true, 
          type: error.msg.match_type 
        }, () => {
            this.props.onAlertChange('error_type');
        });
      }
      if (error.msg.match_string) {
        this.setState({ 
          show: true, 
          type: error.msg.match_string 
        }, () => {
            this.props.onAlertChange('error_string');
        });
      }
    } 

    if (message !== prevProps.message) {
      if (message.addIgnoreItem) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.addIgnoreItem,
        });
      }
      if (message.updateIgnoreItem) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.updateIgnoreItem,
        });
      }
      if (message.deleteIgnoreItem) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.deleteIgnoreItem,
        });
      }
    }
  }

  render() {
    let resultBox;

    const alertBox = (
      <Alert key={this.state.type} variant='danger'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );

    const successBox = (
      <Alert key={this.state.type} variant='success'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );
    
    if (this.state.success) {
      resultBox = successBox;
    } else {
      resultBox = alertBox
    }
    
    return (
      <Fragment>
        {this.state.show ? resultBox : ''}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(IgnoreItemFormAlert);