import React, { Component, Fragment } from 'react';

class Title extends Component {
  render() {
    return (
      <Fragment>
        <div style={{ display: 'flex', alignItems: 'center', flex: '0 0 100%' }}>
        <img style={{ maxHeight: 32, maxWidth: 32, marginRight: 10 }} src={ this.props.program.icon } alt={ this.props.program.short }></img>
        <h2>{ this.props.program.name }</h2>
        </div>
      </Fragment>
    );
  }
}

export default Title;