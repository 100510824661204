import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ItemFormAlert from './ItemFormAlert';
import { addItem, updateItem } from '../../store/actions/items';
import { BsInfoCircle } from 'react-icons/bs';
import { FaEdit, FaLeaf } from 'react-icons/fa';

class ItemModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      ready: false,
      id: this.props.data.item,

      item: '',
      description: '',
      item_type: 'Inventory Type',
      price: 0.00,
      mia_price: 0.00,
      orl_price: 0.00,
      boxes_layer: 0,
      layers_pallet: 0,
      boxes_pallet: 0,
      mia_freight: 1.00,
      orl_freight: 1.00,
      box_type: 'Small',

      error_item: true,
      error_description: true,
      error_price: false,
      error_mia_price: false,
      error_orl_price: false,
      error_bl: false,
      error_lp: false,
      error_bp: false,
      error_mia: false,
      error_orl: false,
    };
  }

  static propTypes = {
    addItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { item, description, item_type, price, mia_price, orl_price, boxes_layer, layers_pallet, boxes_pallet, mia_freight, orl_freight, box_type } = this.props.data;
      this.setState({ 
        id: item, 
        item, 
        description, 
        item_type, 
        price,
        mia_price,
        orl_price,
        boxes_layer: boxes_layer === null ? 0 : boxes_layer, 
        layers_pallet: layers_pallet === null ? 0 : layers_pallet, 
        boxes_pallet: boxes_pallet === null ? 0 : boxes_pallet, 
        mia_freight, 
        orl_freight, 
        box_type,
        error_item: false,
        error_description: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.item !== prevState.item) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.description !== prevState.description) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.price !== prevState.price) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.mia_price !== prevState.mia_price) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.orl_price !== prevState.orl_price) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.boxes_layer !== prevState.boxes_layer) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.layers_pallet !== prevState.layers_pallet) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.boxes_pallet !== prevState.boxes_pallet) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.mia_freight !== prevState.mia_freight) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.orl_freight !== prevState.orl_freight) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.box_type !== prevState.box_type) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addItem) {
        this.setState({
          ready: false,
          item: '',
          description: '',
          item_type: 'Inventory Type',
          price: 0.00,
          mia_price: 0.00,
          orl_price: 0.00,
          boxes_layer: 0,
          layers_pallet: 0,
          boxes_pallet: 0,
          mia_freight: 1.00,
          orl_freight: 1.00,
          box_type: 'Small',
          error_item: true,
          error_description: true,
          error_bl: false,
          error_lp: false,
          error_bp: false,
          error_mia: false,
          error_orl: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateItem) {
        this.setState({
          ready: false,
          item: '',
          description: '',
          item_type: 'Inventory Type',
          price: 0.00,
          mia_price: 0.00,
          orl_price: 0.00,
          boxes_layer: 0,
          layers_pallet: 0,
          boxes_pallet: 0,
          mia_freight: 1.00,
          orl_freight: 1.00,
          box_type: 'Small',
          error_item: false,
          error_description: false,
          error_bl: false,
          error_lp: false,
          error_bp: false,
          error_mia: false,
          error_orl: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_item && !this.state.error_description && !this.state.error_bl && !this.state.error_lp && !this.state.error_bp && !this.state.error_mia && !this.state.error_orl);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    var inputVal = e.target.value;
    var inputName = e.target.name;
    if (inputName === 'box_type') {
      if (inputVal === 'Small') {
        this.setState({ mia_freight: 1.00 , orl_freight: 1.00 });
      } else if (inputVal === 'Big') {
        this.setState({ mia_freight: 2.25 , orl_freight: 3.50 });
      } else if (inputVal === 'Tofu') {
        this.setState({ mia_freight: 0.75 , orl_freight: 0.75 });
      }
    }
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputVal = e.target.value;
    var inputName = e.target.name;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      if (inputName === 'item') {
        this.setState({ error_item: false });
      } else {
        this.setState({ error_description: false });
      }
    } else {
      if (inputName === 'item') {
        this.setState({ error_item: true });
      } else {
        this.setState({ error_description: true });
      }
    }
  }

  onIntChange = (e) => {
    var inputVal = e.target.value;
    var inputName = e.target.name;

    // Only allows digits
    const re = /^[0-9\b]+$/;
    if (inputVal === '' || re.test(inputVal)) {
      if (inputName === 'boxes_layer') {
        this.setState({
          [inputName]: inputVal,
          boxes_pallet: inputVal * this.state.layers_pallet
        });
      } else if (inputName === 'layers_pallet') {
        this.setState({
          [inputName]: inputVal,
          boxes_pallet: this.state.boxes_layer * inputVal
        });
      }
    }

    // Error if value is longer than 3 characters or if value has 0 non-digit characters
    if (inputVal.length > 3 || inputVal.replace(/\D/g, '').length === 0) {
      if (inputName === 'boxes_layer') {
        this.setState({ error_bl: true });
      } else if (inputName === 'layers_pallet'){
        this.setState({ error_lp: true });
      } else if (inputName === 'boxes_pallet') {
        this.setState({ error_bp: true });
      }
    } else {
      if (inputName === 'boxes_layer') {
        this.setState({ error_bl: false });
      } else if (inputName === 'layers_pallet'){
        this.setState({ error_lp: false });
      } else if (inputName === 'boxes_pallet') {
        this.setState({ error_bp: false });
      }
    }
  }

  onPriceChange = (e) => {    
    var price = e.target.value;
    var inputName = e.target.name;

    // Only allows digits and one decimal point
    const re = /^\d*\.?\d{0,2}$/;
    if (price === '' || re.test(price)) {
      this.setState({ [e.target.name]: e.target.value });
    }

    // Error if string has a decimal but no leading or tailing numbers OR if string has 0 non-digit characters
    if ((price.indexOf('.') >=0 && (price.split('.')[0].length === 0 || price.split('.')[1].length === 0)) || price.replace(/\D/g, '').length === 0) {
      if (inputName === 'price') {
        this.setState({ error_price: true });
      } else if (inputName === 'mia_price') { 
        this.setState({ error_mia_price: true });
      } else {
        this.setState({ error_orl_price: true });
      }
    } else {
      if (inputName === 'price') {
        this.setState({ error_price: false });
      } else if (inputName === 'mia_price') { 
        this.setState({ error_mia_price: false });
      } else {
        this.setState({ error_orl_price: false });
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { id, item, description, item_type, price, mia_price, orl_price, boxes_layer, layers_pallet, boxes_pallet, mia_freight, orl_freight, box_type } = this.state;
    const itemEntry = { 
      item, 
      description, 
      item_type,
      price: Number.parseFloat(price).toFixed(2), 
      mia_price: Number.parseFloat(mia_price).toFixed(2), 
      orl_price: Number.parseFloat(orl_price).toFixed(2), 
      boxes_layer: parseInt(boxes_layer, 10), 
      layers_pallet: parseInt(layers_pallet, 10), 
      boxes_pallet: parseInt(boxes_pallet, 10),
      mia_freight: Number.parseFloat(mia_freight).toFixed(2), 
      orl_freight: Number.parseFloat(orl_freight).toFixed(2), 
      box_type 
    };
    this.setState({
      error_item: false,
      error_description: false,
    });
    if (this.props.editMode) {
      this.props.updateItem(id, itemEntry);  
    } else {
      this.props.addItem(itemEntry);
    }  
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { item, description, item_type, price, mia_price, orl_price, boxes_layer, layers_pallet, mia_freight, orl_freight, box_type } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    const infoPopover = (
      <Popover>
        <Popover.Content>
          Values must be whole numbers only.
        </Popover.Content>
      </Popover>
    ); 

    const freightPopover = (
      <Popover>
        <Popover.Content>
          Prices must have <strong>at most</strong> 2 decimal places.
        </Popover.Content>
      </Popover>
    ); 

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
          {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Item</div>) : (<div className='d-flex align-items-center'><FaLeaf/>&nbsp;Add Item</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Item</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='item'
                  onChange={this.onTextChange}
                  value={ item }
                  className={
                    this.state.error_item
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Description</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='description'
                  onChange={this.onTextChange}
                  value={ description }
                  className={
                    this.state.error_description
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Type</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  name='item_type'
                  onChange={this.onChange}
                  value={ item_type }
                  required
                  disabled
                 />
              </Col>
            </Form.Group>

            <br />
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Price</Form.Label>
              <Col sm={3}>
                <Form.Label>Base</Form.Label>
                <Form.Control 
                  type='text'
                  name='price'
                  onChange={this.onPriceChange}
                  value={ price }
                  className={
                    this.state.error_price
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
              <Col sm={3}>
                <Form.Label>MIAMI2017</Form.Label>
                <Form.Control 
                  type='text'
                  name='mia_price'
                  onChange={this.onPriceChange}
                  value={ mia_price }
                  className={
                    this.state.error_mia_price
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
              <Col sm={3}>
                <Form.Label>ORLA2017 Price</Form.Label>
                <Form.Control 
                  type='text'
                  name='orl_price'
                  onChange={this.onPriceChange}
                  value={ orl_price }
                  className={
                    this.state.error_orl_price
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <br />
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Info{' '}
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 1000 }}
                  overlay={infoPopover}
                >
                <Button
                  variant='light'
                  className='d-inline-flex align-items-center'
                >
                  <BsInfoCircle />
                </Button>
                </OverlayTrigger>
              </Form.Label>
              <Col sm={3}>
                <Form.Label>Boxes per Layer</Form.Label>
                <Form.Control 
                  type='text'
                  maxLength='3'
                  name='boxes_layer'
                  onChange={this.onIntChange}
                  value={ boxes_layer }
                  className={
                    this.state.error_bl
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Layers per Pallet</Form.Label>
                <Form.Control 
                  type='text'
                  maxLength='3'
                  name='layers_pallet'
                  onChange={this.onIntChange}
                  value={ layers_pallet }
                  className={
                    this.state.error_lp
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Boxes per Pallet</Form.Label>
                <Form.Control
                  type='text'
                  name='boxes_pallet'
                  value={ boxes_layer * layers_pallet }
                  className={
                    this.state.error_bp
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  disabled
                />
              </Col>
            </Form.Group>

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Box Type
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Small'
                    label='Small'
                    name='box_type'
                    value='Small'
                    checked={box_type === 'Small'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='Big'
                    label='Big'
                    name='box_type'
                    value='Big'
                    checked={box_type === 'Big'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='Tofu'
                    label='Tofu'
                    name='box_type'
                    value='Tofu'
                    checked={box_type === 'Tofu'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <br />
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Freight{' '}
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 1000 }}
                  overlay={freightPopover}
                >
                <Button
                  variant='light'
                  className='d-inline-flex align-items-center'
                >
                  <BsInfoCircle />
                </Button>
                </OverlayTrigger>
              </Form.Label>
              <Col sm={4}>
                <Form.Label>Miami</Form.Label>
                <Form.Control 
                  type='text'
                  name='mia_freight'
                  value={ parseFloat(mia_freight).toFixed(2) }
                  className={
                    this.state.error_mia
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Orlando</Form.Label>
                <Form.Control 
                  type='text'
                  name='orl_freight'
                  value={ parseFloat(orl_freight).toFixed(2) }
                  className={
                    this.state.error_orl
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  disabled
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <ItemFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.messages,
});

export default connect(mapStateToProps, { addItem, updateItem })(ItemModal);