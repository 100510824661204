import { combineReducers } from 'redux';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import reports from './reports';
import dispatches from './dispatches';
import customers from './customers';
import required_customers from './required_customers';
import customer_shipping_locations from './customer_shipping_locations';
import restricted_item_locations from './restricted_item_locations';
import customer_restricted_items from './customer_restricted_items';
import items from './items';
import ignore_items from './ignore_items';

export default combineReducers({
  errors,
  messages,
  auth,
  reports,
  dispatches,
  customers,
  required_customers,
  customer_shipping_locations,
  restricted_item_locations,
  customer_restricted_items,
  items,
  ignore_items,
});