import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AuthAlert extends Component {
  state = {
    show: false,
    type: '',
  };

  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    
    if (error !== prevProps.error) {
      if (error.msg.message) {
        this.setState({ 
          show: true, 
          type: error.msg.message 
        });
      }
      if (error.msg.non_field_errors) {
        this.setState({ 
          show: true, 
          type: error.msg.non_field_errors 
        });
      }
      if (error.msg.password) {
        this.setState({ 
          show: true, 
          type: error.msg.password,
        }, () => {
          this.props.onAlertChange('error_password');
          this.props.onAlertChange('error_password2');
        });
      }      
      if (error.msg.username) {
        if (error.msg.username[0].includes('exists')) {
          this.setState({ 
            show: true, 
            type: error.msg.username,
          }, () => {
            this.props.onAlertChange('error_username');
          });
        } else {
          this.setState({ 
            show: true, 
            type: error.msg.username,
          }, () => {
            this.props.onAlertChange('error_username');
          });
        }
      }
      if (error.msg[0]) {
        this.setState({ 
          show: true, 
          type: error.msg[0],
        }, () => {
          this.props.onAlertChange('error_code');
        });
      }
    } 

    if (message !== prevProps.message) {
      if (message.passwordNotMatch) {
        this.setState({ 
          show: true, 
          type: message.passwordNotMatch,
        }, () => {
          this.props.onAlertChange('error_password');
          this.props.onAlertChange('error_password2');
        });
      }
      if (message.nameEmpty) {
        this.setState({ 
          show: true, 
          type: message.nameEmpty,
        }, () => {
          this.props.onAlertChange('error_full_name');
        });
      }
    }
  }

  render() {
    const alertBox = (
      <Alert key={this.state.type} variant='danger'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );

    return (
      <Fragment>
        {this.state.show ? alertBox : ''}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(AuthAlert);