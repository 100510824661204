import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class RestrictedItemLocationFormAlert extends Component {
  state = {
    show: false,
    success: false,
    type: '',
  };

  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    
    if (error !== prevProps.error) {
      if (error.msg.message) {
        this.setState({ 
          show: true, 
          type: error.msg.message 
        });
      }
      if (error.msg.item) {
        this.setState({ 
          show: true, 
          type: error.msg.item 
        }, () => {
            this.props.onAlertChange('error_item');
        });
      }
      if (error.msg.restricted_locations) {
        this.setState({ 
          show: true, 
          type: error.msg.restricted_locations 
        }, () => {
            this.props.onAlertChange('error_restricted_locations');
        });
      }
    } 

    if (message !== prevProps.message) {
      if (message.addRestrictedItemLocation) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.addRestrictedItemLocation,
        });
      }
      if (message.updateRestrictedItemLocation) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.updateRestrictedItemLocation,
        });
      }
      if (message.deleteRestrictedItemLocation) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.deleteRestrictedItemLocation,
        });
      }
    }
  }

  render() {
    let resultBox;

    const alertBox = (
      <Alert key={this.state.type} variant='danger'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );

    const successBox = (
      <Alert key={this.state.type} variant='success'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );
    
    if (this.state.success) {
      resultBox = successBox;
    } else {
      resultBox = alertBox
    }
    
    return (
      <Fragment>
        {this.state.show ? resultBox : ''}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(RestrictedItemLocationFormAlert);