import { 
  CREATE_MESSAGE, 
  CLEAR_ALL 
} from '../actions/types';

const initialState = {};

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MESSAGE:
      return (state = action.payload);
    case CLEAR_ALL:
      return (state = {});
    default:
      return state;
  }
}