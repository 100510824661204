import { 
    GET_RESTRICTED_ITEM_LOCATIONS, 
    ADD_RESTRICTED_ITEM_LOCATION, 
    UPDATE_RESTRICTED_ITEM_LOCATION, 
    DELETE_RESTRICTED_ITEM_LOCATION, 
    CLEAR_ALL 
  } from '../actions/types';
  
  const initialState = {
    restricted_item_locations: [],
  };
  
  export default function restrictedItemLocationsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_RESTRICTED_ITEM_LOCATIONS:
        return {
          ...state,
          restricted_item_locations: action.payload.sort((a, b) => (a.item.toUpperCase() > b.item.toUpperCase()) ? 1 : -1),
        };
      case ADD_RESTRICTED_ITEM_LOCATION:
        return {
          ...state,
          restricted_item_locations: [...state.restricted_item_locations, action.payload].sort((a, b) => (a.item.toUpperCase() > b.item.toUpperCase()) ? 1 : -1),
        };
      case UPDATE_RESTRICTED_ITEM_LOCATION:
        return {
          ...state,
          restricted_item_locations: [...state.restricted_item_locations.filter((item) => item.id !== action.modified), action.payload].sort((a, b) => (a.item.toUpperCase() > b.item.toUpperCase()) ? 1 : -1),
        };
      case DELETE_RESTRICTED_ITEM_LOCATION:
        return {
          ...state,
          restricted_item_locations: state.restricted_item_locations.filter((item) => item.id !== action.payload),
        };
      case CLEAR_ALL:
        return {
          ...state,
          restricted_item_locations: [],
        };
      default:
        return state;
    }
  }