import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_CUSTOMER_RESTRICTED_ITEMS, 
  ADD_CUSTOMER_RESTRICTED_ITEM, 
  UPDATE_CUSTOMER_RESTRICTED_ITEM, 
  DELETE_CUSTOMER_RESTRICTED_ITEM 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'customer_restricted_items';

// GET CUSTOMER RESTRICTED ITEMS
export const getCustomerRestrictedItems = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CUSTOMER_RESTRICTED_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD CUSTOMER RESTRICTED ITEM
export const addCustomerRestrictedItem = (customer_restricted_item) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, customer_restricted_item, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addCustomerRestrictedItem: 'Customer Restricted Item Added' }));
      dispatch({
        type: ADD_CUSTOMER_RESTRICTED_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE CUSTOMER RESTRICTED ITEM
export const updateCustomerRestrictedItem = (id, customer_restricted_item) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, customer_restricted_item, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateCustomerRestrictedItem: 'Customer Restricted Item Updated' }));
      dispatch({
        type: UPDATE_CUSTOMER_RESTRICTED_ITEM,
        modified: id,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// DELETE CUSTOMER RESTRICTED ITEM
export const deleteCustomerRestrictedItem = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteCustomerRestrictedItem: 'Customer Restricted Item Deleted' }));
      dispatch({
        type: DELETE_CUSTOMER_RESTRICTED_ITEM,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};