import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import CsvToHtmlTable from '../components/common/CsvToHtmlTable';
import { getDispatch, get24HDispatches, getOutput } from '../store/actions/dispatches';
import moment from 'moment';

class Dispatch extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      options_loaded: true,
      result_loaded: true,
      output: 'NONE',
      scriptType: '',
      dispatchTitle: '',
      options: false,
      result: false,
    };
  }

  static propTypes = {
    dispatch: PropTypes.string.isRequired,
    output: PropTypes.string.isRequired,
    getDispatch: PropTypes.func.isRequired,
    get24HDispatches: PropTypes.func.isRequired,
    getOutput: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { output } = this.props;
    this.setState({ output });
    this.props.get24HDispatches('PALLETS - DISPATCH');
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, output, dates } = this.props;
    if (dispatch !== prevProps.dispatch) {
      if (dispatch !== 'NO DISPATCHES') {
        this.props.getOutput(this.props.dispatch);
      }
    }
    if (dates !== prevProps.dates) {
      this.setState({ options: true });
    }
    if (output !== prevProps.output || dispatch !== prevProps.dispatch) {
      this.setState({ output });
      setTimeout(() => {
        this.setState({ result: true });
      }, 100);
    }
  }

  showDates = (e) => {
    this.props.get24HDispatches(e.target.id);
    this.setState({ options_loaded: false });
    setTimeout(() => {
      this.setState({ options_loaded: true, options: true });
    }, 100);
  }

  showOutput = (e) => {
    this.props.getDispatch('PALLETS - DISPATCH', e.target.id);
    this.setState({ result_loaded: false });
    setTimeout(() => {
      this.setState({ result_loaded: true, scriptType: 'PALLETS - DISPATCH', dispatchTitle: 'Pallet Estimates', result: true });
    }, 100);
  }

  render() {
    let options;
    if (this.state.options) {
      if (this.props.dates === 'NO DISPATCHES') {
        options = (
          <div className='loading'>
            <h5 className='mb-4'>
              No dispatches have been submitted.
            </h5>
          </div>
        );
      } else {
        let dates = this.props.dates.split(',');
        options = (
          <div className='loading'>
            {dates.map(date => (
              <Button
                className='mb-2 mr-2'
                key={date}
                id={date}
                onClick={this.showOutput}
                variant='outline-primary'
                size='lg'>
                  {moment(date).format('dddd, MMMM Do YYYY, h:mm a')}
              </Button>
            ))}
          </div>
        );
      }
    }

    let result;
    if (this.state.result) {
      if (this.props.dispatch === 'NO DISPATCHES') {
        result = (
          <div className='loading'>
            <hr></hr>
            <h5 className='mb-4'>
              There is no dispatch listed at this time. Please refresh.
            </h5>
          </div>
        );
      } else {
        let formattedDate = 'Weekday, Month Date Year, Hour:Minute';
        formattedDate = moment(this.props.date).format('dddd, MMMM Do YYYY, h:mm a');

        result = (
        <div className='loading'>
          <hr></hr>
          <h3 className='mb-4'>{this.state.dispatchTitle}</h3>
          <h5 className='mb-4'>
            Submitted on {formattedDate} by <u>{this.props.owner}</u> with <u>{this.props.result}</u>.
          </h5>
          <div className='mb-4'>
            <a href={this.props.input} rel='noopener noreferrer'>
              <Button variant='info'>
                Download Input File
              </Button>
            </a>
            {' '}
            <a href={this.props.dispatch} rel='noopener noreferrer' target='_blank'>
              <Button variant='info'>
              Output file (shown below)
              </Button>
            </a>
          </div>
          <CsvToHtmlTable 
            data={this.state.output} 
            groupBy={this.state.scriptType.includes('VALIDATION') ? false : true} 
            withTotals={this.state.scriptType.includes('VALIDATION') ? false : true} 
            tableClassName='table table-sm table-striped table-bordered table-hover table-responsive-sm loading'
          />
        </div>
        );
      }
    }

    return (
      <div className='page'>
        <Title program={this.props.programs} />
        <br />
        <div className='mt-2'>
          <div className='mb-5'>
            <Button
              className='mb-2 mr-2'
              id='PALLETS - DISPATCH'
              onClick={this.showDates}
              variant='outline-secondary'
              size='lg'
              active={this.state.scriptType === 'PALLETS - DISPATCH'}>
                Refresh Dispatches
            </Button>
            {this.state.options_loaded ? options : 
              <Spinner animation='border' role='status' variant='info' className='d-block m-auto'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            }
          </div>
          {this.state.result_loaded ? result : 
            <Spinner animation='border' role='status' variant='info' className='d-block m-auto'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dates: state.dispatches.dates,
  date: state.dispatches.date,
  owner: state.dispatches.owner,
  result: state.dispatches.result,
  input: state.dispatches.input,
  dispatch: state.dispatches.dispatch,
  output: state.dispatches.output
});

export default connect(mapStateToProps, { getDispatch, get24HDispatches, getOutput })(Dispatch);