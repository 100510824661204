import { 
    GET_CUSTOMER_RESTRICTED_ITEMS, 
    ADD_CUSTOMER_RESTRICTED_ITEM, 
    UPDATE_CUSTOMER_RESTRICTED_ITEM, 
    DELETE_CUSTOMER_RESTRICTED_ITEM, 
    CLEAR_ALL 
  } from '../actions/types';
  
  const initialState = {
    customer_restricted_items: [],
  };
  
  export default function customerRestrictedItemReducer(state = initialState, action) {
    switch (action.type) {
      case GET_CUSTOMER_RESTRICTED_ITEMS:
        return {
          ...state,
          customer_restricted_items: action.payload.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
        };
      case ADD_CUSTOMER_RESTRICTED_ITEM:
        return {
          ...state,
          customer_restricted_items: [...state.customer_restricted_items, action.payload].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
        };
      case UPDATE_CUSTOMER_RESTRICTED_ITEM:
        return {
          ...state,
          customer_restricted_items: [...state.customer_restricted_items.filter((customer) => customer.id !== action.modified), action.payload].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
        };
      case DELETE_CUSTOMER_RESTRICTED_ITEM:
        return {
          ...state,
          customer_restricted_items: state.customer_restricted_items.filter((customer) => customer.id !== action.payload),
        };
      case CLEAR_ALL:
        return {
          ...state,
          customer_restricted_items: [],
        };
      default:
        return state;
    }
  }