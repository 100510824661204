import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ItemFormAlert extends Component {
  state = {
    show: false,
    success: false,
    type: '',
  };

  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    
    if (error !== prevProps.error) {
      if (error.msg.message) {
        this.setState({ 
          show: true, 
          type: error.msg.message 
        });
      }
      if (error.msg.orl_freight) {
        this.setState({ 
          show: true, 
          type: error.msg.orl_freight 
        }, () => {
            this.props.onAlertChange('error_orl');
        });
      }
      if (error.msg.mia_freight) {
        this.setState({ 
          show: true, 
          type: error.msg.mia_freight 
        }, () => {
            this.props.onAlertChange('error_mia');
        });
      }
      if (error.msg.boxes_pallet) {
        this.setState({ 
          show: true, 
          type: error.msg.boxes_pallet 
        }, () => {
            this.props.onAlertChange('error_bp');
        });
      }
      if (error.msg.layers_pallet) {
        this.setState({ 
          show: true, 
          type: error.msg.layers_pallet 
        }, () => {
            this.props.onAlertChange('error_lp');
        });
      }
      if (error.msg.boxes_layer) {
        this.setState({ 
          show: true, 
          type: error.msg.boxes_layer 
        }, () => {
            this.props.onAlertChange('error_bl');
        });
      }
      if (error.msg.price) {
        this.setState({ 
          show: true, 
          type: error.msg.price 
        }, () => {
            this.props.onAlertChange('error_price');
        });
      }
      if (error.msg.mia_price) {
        this.setState({ 
          show: true, 
          type: error.msg.mia_price 
        }, () => {
            this.props.onAlertChange('error_mia_price');
        });
      }
      if (error.msg.orl_price) {
        this.setState({ 
          show: true, 
          type: error.msg.orl_price 
        }, () => {
            this.props.onAlertChange('error_orl_price');
        });
      }
      if (error.msg.description) {
        this.setState({ 
          show: true, 
          type: error.msg.description 
        }, () => {
            this.props.onAlertChange('error_description');
        });
      }
      if (error.msg.item) {
        this.setState({ 
          show: true, 
          type: error.msg.item 
        }, () => {
            this.props.onAlertChange('error_item');
        });
      }
    } 

    if (message !== prevProps.message) {
      if (message.addItem) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.addItem,
        });
      }
      if (message.updateItem) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.updateItem,
        });
      }
      if (message.deleteItem) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.deleteItem,
        });
      }
    }
  }

  render() {
    let resultBox;

    const alertBox = (
      <Alert key={this.state.type} variant='danger'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );

    const successBox = (
      <Alert key={this.state.type} variant='success'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );
    
    if (this.state.success) {
      resultBox = successBox;
    } else {
      resultBox = alertBox
    }
    
    return (
      <Fragment>
        {this.state.show ? resultBox : ''}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(ItemFormAlert);