import React, { Component } from 'react';
import { Table } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import { getCustomers } from '../store/actions/customers';
import { FaSearch } from 'react-icons/fa';

class CustomersEmployee extends Component {
  state = {
    search: '',
  }

  static propTypes = {
    customers: PropTypes.array.isRequired,
    getCustomers: PropTypes.func.isRequired,
  }
  
  componentDidMount() {
    this.props.getCustomers();
  }

  filterCustomers = (e) => {
    let search = e.target.value;
    this.setState({ search });
  }

  render() {
    let filteredCustomers = this.props.customers;

    if (this.state.search.length > 0) {
      filteredCustomers = filteredCustomers.filter(c=> c.name.toUpperCase().includes(this.state.search.toUpperCase()));
    }

    return (
      <div className='page'>
        <Title program={this.props.programs} />
        <br />
        <div className='input-group'>
          <input className='form-control py-2 border-right-0 border' type='text' onChange={this.filterCustomers} placeholder='Search for customers by name...' title='Type in a customer' />
          <span className='input-group-append'>
            <div className='input-group-text bg-transparent'><FaSearch/></div>
          </span>
        </div>
        <div className='flex-grow-1 mt-4'>
          <Table striped bordered hover size='sm'responsive='md'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Fax</th>
                <th>Customer Type</th>
                <th>Price Level</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer) => (
                <tr key={customer.name}>
                  <td>{customer.name}</td>
                  <td>{customer.phone_number}</td>
                  <td>{customer.fax_number}</td>
                  <td>{customer.customer_type}</td>
                  <td>{customer.price_level}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
});

export default connect(mapStateToProps, { getCustomers })(CustomersEmployee);