import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IgnoreItemFormAlert from './IgnoreItemFormAlert';
import { addIgnoreItem, updateIgnoreItem } from '../../store/actions/ignore_items';
import { FaEdit, FaLeaf } from 'react-icons/fa';

class IgnoreItemModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      ready: false,
      id: parseInt(Date.now().toString().slice(-8)),

      match_string: '',
      match_type: 'EM',
      match_field: 'I',

      error_string: true,
      error_type: false,
      error_field: false,
    };
  }

  static propTypes = {
    ignore_items: PropTypes.array.isRequired,
    addIgnoreItem: PropTypes.func.isRequired,
    updateIgnoreItem: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { id, match_string, match_type, match_field } = this.props.data;
      this.setState({ 
        id, 
        match_string, 
        match_type, 
        match_field,
        error_string: false,
        error_type: false,
        error_field: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.match_string !== prevState.match_string) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.match_type !== prevState.match_type) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.match_field !== prevState.match_field) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addIgnoreItem) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          match_string: '',
          match_type: 'EM',
          match_field: 'I',
          error_string: true,
          error_type: false,
          error_field: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateIgnoreItem) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          match_string: '',
          match_type: 'EM',
          match_field: 'I',
          error_string: false,
          error_type: false,
          error_field: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_string && !this.state.error_type && !this.state.error_field);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputVal = e.target.value;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      this.setState({ error_string: false });
    } else {
      this.setState({ error_string: true });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { id, match_string, match_type, match_field } = this.state;
    const ignoreItemEntry = { 
      id,
      match_string, 
      match_type, 
      match_field
    };
    this.setState({
      error_string: false,
    });
    console.log(ignoreItemEntry);
    if (this.props.editMode) {
      this.props.updateIgnoreItem(id, ignoreItemEntry);  
    } else {
      this.props.addIgnoreItem(ignoreItemEntry);
    }
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { match_string, match_type, match_field } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
          {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Ignore Item</div>) : (<div className='d-flex align-items-center'><FaLeaf/>&nbsp;Add Ignore Item</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Match String</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='match_string'
                  onChange={this.onTextChange}
                  value={ match_string }
                  className={
                    this.state.error_string
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Type
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Exact match'
                    label='Exact match'
                    name='match_type'
                    value='EM'
                    checked={match_type === 'EM'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='Contains string'
                    label='Contains string'
                    name='match_type'
                    value='CS'
                    checked={match_type === 'CS'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Field
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Item only'
                    label='Item only'
                    name='match_field'
                    value='I'
                    checked={match_field === 'I'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='Description only'
                    label='Description only'
                    name='match_field'
                    value='D'
                    checked={match_field === 'D'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='Both'
                    label='Both'
                    name='match_field'
                    value='B'
                    checked={match_field === 'B'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>           
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <IgnoreItemFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ignore_items: state.ignore_items.ignore_items,
  message: state.messages,
});

export default connect(mapStateToProps, { addIgnoreItem, updateIgnoreItem })(IgnoreItemModal);