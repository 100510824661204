import React, { Component } from 'react';
import { CardDeck, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Settings extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  filterPrograms = (programs, role) => {
    let displayPrograms = [];
    for (var key in programs) {
      if (programs[key]['role_access'].indexOf(role) >= 0) {
        displayPrograms.push(programs[key]);
      }
    }
    return displayPrograms;
  };

  render() {
    const { user } = this.props.auth;
    var filteredPrograms = this.filterPrograms(this.props.programs, user.role);

    return (
      <CardDeck className='page'>
        <h1 style={{ display: 'flex', alignItems: 'center', flex: '0 0 100%' }}>Settings</h1>
        <Row>
          {filteredPrograms.map(p => (
            <Card className='settings-card' key={ p.id }>
              <Card.Img variant='top mx-auto' src={ p.icon } alt={ p.short }/>
              <Card.Body>
                <Card.Title className='font-weight-bold'>{ p.name }</Card.Title>
                <Link to={ p.path } className='stretched-link' style={{ textDecoration: 'none', outline: 0, color: 'black' }}>
                  <Card.Text className='mb-0'>{ p.description }</Card.Text>
                </Link>
              </Card.Body>
            </Card>
          ))}
        </Row>
      </CardDeck>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Settings);