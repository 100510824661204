export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_OUTPUT = 'GET_OUTPUT';

export const GET_DISPATCH = 'GET_DISPATCH';
export const GET_24H_DISPATCHES = 'GET_24H_DISPATCHES';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

export const GET_REQUIRED_CUSTOMERS = 'GET_REQUIRED_CUSTOMERS';
export const ADD_REQUIRED_CUSTOMER = 'ADD_REQUIRED_CUSTOMER';
export const UPDATE_REQUIRED_CUSTOMER = 'UPDATE_REQUIRED_CUSTOMER';
export const DELETE_REQUIRED_CUSTOMER = 'DELETE_REQUIRED_CUSTOMER';

export const GET_CUSTOMER_SHIPPING_LOCATIONS = 'GET_CUSTOMER_SHIPPING_LOCATIONS';
export const ADD_CUSTOMER_SHIPPING_LOCATION = 'ADD_CUSTOMER_SHIPPING_LOCATION';
export const UPDATE_CUSTOMER_SHIPPING_LOCATION = 'UPDATE_CUSTOMER_SHIPPING_LOCATION';
export const DELETE_CUSTOMER_SHIPPING_LOCATION = 'DELETE_CUSTOMER_SHIPPING_LOCATION';

export const GET_ITEMS = 'GET_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';

export const GET_IGNORE_ITEMS = 'GET_IGNORE_ITEMS';
export const ADD_IGNORE_ITEM = 'ADD_IGNORE_ITEM';
export const UPDATE_IGNORE_ITEM = 'UPDATE_IGNORE_ITEM';
export const DELETE_IGNORE_ITEM = 'DELETE_IGNORE_ITEM';

export const GET_RESTRICTED_ITEM_LOCATIONS= 'GET_RESTRICTED_ITEM_LOCATIONS';
export const ADD_RESTRICTED_ITEM_LOCATION = 'ADD_RESTRICTED_ITEM_LOCATION';
export const UPDATE_RESTRICTED_ITEM_LOCATION = 'UPDATE_RESTRICTED_ITEM_LOCATION';
export const DELETE_RESTRICTED_ITEM_LOCATION = 'DELETE_RESTRICTED_ITEM_LOCATION';

export const GET_CUSTOMER_RESTRICTED_ITEMS = 'GET_CUSTOMER_RESTRICTED_ITEMS';
export const ADD_CUSTOMER_RESTRICTED_ITEM = 'ADD_CUSTOMER_RESTRICTED_ITEM';
export const UPDATE_CUSTOMER_RESTRICTED_ITEM = 'UPDATE_CUSTOMER_RESTRICTED_ITEM';
export const DELETE_CUSTOMER_RESTRICTED_ITEM = 'DELETE_CUSTOMER_RESTRICTED_ITEM';

export const CLEAR_ALL = 'CLEAR_ALL';