import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Alert, Popover, OverlayTrigger, Spinner } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import CsvToInteractiveTable from '../components/common/CsvToInteractiveTable';
import DragAndDrop from '../components/elements/DragAndDrop';
import { BsInfoCircle } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';

class Pallets extends Component {
  constructor (props) {
    super();
    
    this.state = {
      file: [],
      fileName: '',
      ready: false,
      uploadSuccess: false,
      wrongFiletype: false,
      results: false,
      response: '',
      errorResponse: '',
      errorResponseMessage: '',

      loaded: true,

      outputFileName: '',
      
      interactive: false,
      totalUnconfirmed: 1,
      finalCount: [],
      submitConfirmation: false,
    };
    // this.handleUnload = this.handleUnload.bind(this);
  }

  // componentDidMount() {
  //   window.addEventListener('beforeunload', this.handleUnload);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('beforeunload', this.handleUnload);
  // }

  // handleUnload(e) {
  //   var message = "hi";

  //   (e || window.event).returnValue = message; //Gecko + IE
  //   return message;
  // }

  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  handleDrop = (newFileList) => {
    let fileName = newFileList[0].name;
    let sections = fileName.split('.');
    if (sections[sections.length - 1] === 'CSV') {
      this.stageFile(true, newFileList[0], fileName);
    } else {
      this.stageFile(false, '');
    }
  }

  handleClick = (e) => {
    this.refs.fileUploader.click();
  }

  fileInputClicked = (e) => {
    e.target.value = '';
  }

  fileInputChanged = (e) => {
    if (document.getElementById('file').files[0] !== undefined) {
      let file = document.getElementById('file').files[0];
      let fileName = file.name;
      let sections = fileName.split('.');
      if (sections[sections.length - 1] === 'CSV') {
        this.stageFile(true, file, fileName);
      } else {
        this.stageFile(false);
      }
    }
  }

  stageFile = (success, file, fileName) => {
    if (success) {
      this.setState({file: []});
      this.setState(prevState => ({
        file: prevState.file.concat(file)
      }));
      this.setState({fileName: fileName, ready: true, wrongFiletype: false, interactive: false, submitConfirmation: false});
    } else {
      this.setState({file: [], ready: false, wrongFiletype: true, interactive: false, submitConfirmation: false});
    }
  }

  async uploadFile(dispatchFlag) {
    const promises = [];
    promises.push(this.sendRequest(this.state.file[0], dispatchFlag));
    
    try {
      await Promise.all(promises);
      this.setState({ uploadSuccess: true });
    } catch (e) {
      this.setState({ uploadSuccess: false, response: '', errorResponse: 'BAD FILE UPLOAD', results: true });
    }
  }

  sendRequest = (file, dispatchFlag) => {
    const { user } = this.props.auth;
    const req = new XMLHttpRequest();
    var promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('file', file);
      formData.append('user', user.username);
      if (dispatchFlag) {
        formData.append('script', 'PALLETS - DISPATCH');
        formData.append('output_file_name', this.state.outputFileName);
        formData.append('final_count', this.state.finalCount);
      } else {
        formData.append('script', 'PALLETS');
      }
      req.addEventListener('load', () => {
        if (req.status === 200) {
          if (!dispatchFlag) {
            let response = req.response.split('|||');
            this.setState({ loaded: true, response: response[1], errorResponse: '', errorResponseMessage: '', results: true, interactive: true, outputFileName: response[0] });
            let rowCount = req.response.split('\n').length - 2;
            this.setState({ totalUnconfirmed: rowCount, finalCount: new Array(rowCount).fill(0) });
          }
        } else if (req.status === 422) {
          this.setState({ loaded: true, response: '', errorResponse: 'WRONG FORMAT', errorResponseMessage: req.response, results: true });
        } else if (req.status === 409) {
          this.setState({ loaded: true, response: '', errorResponse: 'MISSING INFO', errorResponseMessage: req.response, results: true });
        } else if (req.status === 500) {
          this.setState({ loaded: true, response: '', errorResponse: 'SERVER ERROR', results: true });
        } else {
          this.setState({ loaded: true, response: '', errorResponse: 'OTHER ERROR', results: true });
        }
      })
      if (dispatchFlag) {
        req.open('POST', `${process.env.REACT_APP_API_URL}/dispatch/`);
        req.send(formData);
        this.setState({
          submitConfirmation: true,
          results: false,
          response: '',
          interactive: false,
        });
      } else {
        req.open('POST', `${process.env.REACT_APP_API_URL}/upload/`);
        req.send(formData);
        this.setState({ loaded: false });
      }
    });

    return promise;
  }

  displayResults = (e) => {
    this.uploadFile(e.target.id === 'DISPATCH');
    
    this.setState({
      ready: false,
      uploadSuccess: false,
      wrongFiletype: false,
      submitConfirmation: e.target.id === 'DISPATCH'
    });
  }

  resetResults = (e) => {
    this.setState({
      file: [],
      fileName: '',
      ready: false,
      uploadSuccess: false,
      wrongFiletype: false,
      results: false,
      response: '',
      errorResponse: '',
      errorResponseMessage: '',

      outputFileName: '',
      interactive: false,
    });
  }

  onIntChange = (e) => {
    var input = e.target.id;
    var inputVal = e.target.value;

    // Only allows digits
    const re = /^[0-9\b]+$/;
    if (inputVal === '' || re.test(inputVal)) {
      document.getElementById(input).value = inputVal;
    }
  }

  switchButtonMode = (rowIdx, editModeFlag) => {
    let rowButton = `button-${rowIdx}`;
    let rowForm = `form-${rowIdx}`;
    let tableRow = `row-${rowIdx}`;

    let checkButton = `check-${rowIdx}`;
    let editButton = `edit-${rowIdx}`;

    // CHECK -> EDIT
    if (editModeFlag) {
      document.getElementById(checkButton).className = 'hidden';
      document.getElementById(editButton).className = '';
      
      document.getElementById(rowButton).className = 'btn btn-outline-primary';
      document.getElementById(rowForm).disabled = true;
      document.getElementById(tableRow).style.backgroundColor = '#d4edda';

      let newAddition = document.getElementById(rowForm).value;
      let newFinalCount = this.state.finalCount;
      newFinalCount[rowIdx] = Number.parseInt(newAddition, 10);
      
      this.setState({ 
        totalUnconfirmed: this.state.totalUnconfirmed - 1, 
        finalCount: newFinalCount
      });
    // EDIT -> CHECK
    } else {
      document.getElementById(editButton).className = 'hidden';
      document.getElementById(checkButton).className = '';

      document.getElementById(rowButton).className = 'btn btn-primary';
      document.getElementById(rowForm).disabled = false;
      if (rowIdx % 2 === 0) { // FIRST ROW (non-header)
        document.getElementById(tableRow).style.backgroundColor = 'rgba(0,0,0,.05)';
      } else { // NEXT ROW
        document.getElementById(tableRow).style.backgroundColor = '#ffffff';
      }
      
      this.setState({ totalUnconfirmed: this.state.totalUnconfirmed + 1 });
    }
  }

  confirmRow = (e) => {
    let rowIdx = e.target.id.split('-')[1];
    let editButton = `edit-${rowIdx}`;

    // CONFIRM ROW
    if (document.getElementById(editButton).className === 'hidden') {
      this.switchButtonMode(rowIdx, true);
    // UNCONFIRM ROW
    } else {
      this.switchButtonMode(rowIdx, false);
    }
  }

  confirmAll = (e) => {
    let buttonID = e.target.id;
    let maxRow = this.state.finalCount.length;

    if (buttonID === 'CHECK ALL') {
      for (var i = 0; i < maxRow; i++) {
        // IF THE BUTTON LOOKS LIKE A CHECK, MAKE IT INTO EDIT
        let checkButton = `check-${i}`;

        if (document.getElementById(checkButton).className === '') {
          this.switchButtonMode(i, true);
        }
      }
    }
    
    if (buttonID === 'CLEAR ALL') {
      for (var j = 0; j < maxRow; j++) {
        // IF THE BUTTON LOOKS LIKE AN EDIT, MAKE IT INTO A CHECK
        let editButton = `edit-${j}`;

        if (document.getElementById(editButton).className === '') {
          this.switchButtonMode(j, false);
        }
      }
    }

    if (buttonID === 'CHECK ALL') {
      this.setState({ totalUnconfirmed: 0 });
    } else {
      this.setState({ totalUnconfirmed: maxRow });
    }
  }

  render() {
    const activeButton = (<Button variant='primary' className='mb-2 mr-2' onClick={this.displayResults}>ESTIMATE</Button>);
    const disabledButton = (<Button variant='secondary' className='mb-2 mr-2' disabled>ESTIMATE</Button>);

    const activeClearButton = (<Button variant='outline-secondary' id='CLEAR' className='mb-2 ml-2' onClick={this.resetResults}>Clear</Button>);

    const activeSubmitButton = (<Button variant='primary' id='DISPATCH' className='mb-2' onClick={this.displayResults}>SUBMIT TO DISPATCH</Button>);
    const disabledSubmitButton = (<Button variant='secondary' className='mb-2' disabled>SUBMIT TO DISPATCH</Button>);

    const confirmAllButton = (<Button variant='primary' id='CHECK ALL' className='float-right mb-2' onClick={this.confirmAll}><FaCheck/> ALL</Button>);
    const unconfirmAllButton = (<Button variant='secondary' id='CLEAR ALL' className='float-right mb-2' onClick={this.confirmAll}>CLEAR ALL</Button>);

    const stagedFile = (<Alert key='stagedFile' variant='success'>{this.state.fileName}</Alert>);
    const warningMessage = (<Alert key='wrongFiletype' variant='danger'>Wrong filetype. Please try again.</Alert>);

    const wrongFormatAlert = (<Alert key='wrongFormat' variant='danger'>Wrong format inside CSV. Please try again.<br />{this.state.errorResponseMessage}</Alert>);
    let missingInfoAlert = (<Alert key='missingInfo' variant='danger'><div dangerouslySetInnerHTML={{ __html: this.state.errorResponseMessage }} /></Alert>);
    const serverErrorAlert = (<Alert key='serverError' variant='danger'>500 Error: Something went wrong in the server. Please contact your administrator.</Alert>);
    const otherErrorAlert = (<Alert key='otherError' variant='danger'>Something went wrong. Please contact your administrator.</Alert>);
    const badFileUploadAlert = (<Alert key='badFileUpload' variant='danger'>Something went wrong uploading the file. Please contact your administrator.</Alert>);

    const completedDispatch = (<Alert key='completedDispatch' variant='success'>Completed Dispatch! Please check the Dispatch Center for the report.</Alert>);

    let errorResult;
    var errorResponse = this.state.errorResponse;
    if (errorResponse === 'WRONG FORMAT') {
      errorResult = wrongFormatAlert;
    } else if (errorResponse === 'MISSING INFO') {
      errorResult = missingInfoAlert;
    } else if (errorResponse === 'SERVER ERROR') {
      errorResult = serverErrorAlert;
    } else if (errorResponse === 'OTHER ERROR') {
      errorResult = otherErrorAlert;
    } else if (errorResponse === 'BAD FILE UPLOAD') {
      errorResult = badFileUploadAlert;
    }

    var tableResult = (
      <div>
        { this.state.submitConfirmation ? '' : 
          <CsvToInteractiveTable 
            data={this.state.response} 
            tableClassName='table table-sm table-striped table-bordered table-hover table-responsive-sm loading'
            formFunction={this.onIntChange}
            buttonFunction={this.confirmRow}
          />
        }
      </div>
    );

    const popover = (
      <Popover>
        <Popover.Title as='h3'>Memorized Liam Report</Popover.Title>
        <Popover.Content>
          Use <strong>Sales Report</strong> from QB.<br />
          Headers should include: 
          <ul className='mb-0'>
            <li>Date</li>
            <li>Num</li>
            <li>Memo</li>
            <li>Name</li>
            <li>Ship To Address 1</li>
            <li>Item</li>
            <li>Qty</li>
          </ul>
        </Popover.Content>
      </Popover>
    );    

    return (
      <div className='page'>
        <Title program={ this.props.programs } />
        <DragAndDrop handleDrop={ this.handleDrop }>
          <div className='drag-target'>
            <div id='clickable-area' onClick={ this.handleClick }>
              <div className='drag-target-text' id='instruction'>
                Drag and drop your .CSV file,<br />
                or click here to select a file.
              </div>
              <input type='file' id='file' accept='.CSV' ref='fileUploader' onClick={ this.fileInputClicked } onChange={ this.fileInputChanged } className='hidden'/>
            </div>
          </div>
        </DragAndDrop>
        <Prompt when={this.state.interactive && !this.state.submitConfirmation} message={"Are you sure you want to leave this page?\nYou have not submitted the pallet final counts for dispatch."} />
        <br />
        <div className='uploaded-file-stage'>
          <div className='uploaded-file-header'>
            <h3>Uploaded File:</h3>
            <div className='uploaded-file-info'>
              <OverlayTrigger 
                placement='right'
                delay={{ show: 250, hide: 1000 }}
                overlay={popover}
              >
                <Button
                  variant='light'
                  className='d-inline-flex align-items-center'
                >
                  <BsInfoCircle />
                </Button>
              </OverlayTrigger>
            </div>
          </div>          
          {(this.state.ready || this.state.results) && !this.state.wrongFiletype ? stagedFile : ''}
          {this.state.wrongFiletype ? warningMessage : ''}
        </div>
        <div className='uploaded-file-buttons'>
          {this.state.ready ? activeButton : disabledButton}
          {this.state.ready ? activeClearButton : ''}
        </div>
        <div className='uploaded-file-results'>
          {this.state.loaded ? '' :
            <Spinner animation='border' role='status' variant='info' className='d-block m-auto'>
              <span className='sr-only'>Loading...</span>
            </Spinner> 
          }
          { this.state.results && this.state.totalUnconfirmed === 0 && !this.state.submitConfirmation ? activeSubmitButton : disabledSubmitButton }
          { this.state.results && this.state.totalUnconfirmed > 0 ? confirmAllButton : unconfirmAllButton }
          { this.state.submitConfirmation ? completedDispatch : '' }

          { this.state.results && this.state.response && this.state.loaded ? tableResult : '' }

          {this.state.results && this.state.errorResponse && this.state.loaded ? errorResult : ''}
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Pallets);