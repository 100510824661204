import React, { Component } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthAlert from '../components/elements/AuthAlert';
import { register } from '../store/actions/auth';
import { createMessage } from '../store/actions/messages';

class RegisterRole extends Component {
  constructor (props) {
    super();
    
    this.state = {
      full_name: '',
      username: '',
      password: '',
      password2: '',
      code: '',

      error_full_name: false,
      error_username: false,
      error_password: false,
      error_password2: false,
      error_code: false,
    };
  }

  static propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { full_name, username, password, password2, code } = this.state;
    this.setState({
      error_full_name: false,
      error_username: false,
      error_password: false,
      error_password2: false,
      error_code: false,
    });

    if (full_name === '') {
      this.props.createMessage({ nameEmpty: 'This field may not be blank.' });
    } else if (password !== password2) {
      this.props.createMessage({ passwordNotMatch: 'Passwords do not match.' });
    }

    if (full_name !== '' && password === password2) {
      let roleNum;
      if (this.props.employeeMode) {
        roleNum = 1;
      } else {
        roleNum = 2;
      }
      
      const newUser = {
        full_name,
        username,
        password,
        role: roleNum,
        code,
      };
      this.props.register(newUser);
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to='/' />;
    }
    const { full_name, username, password, password2, code } = this.state;
    return (
      <div id='login-card' className='col-md-3 m-auto'>
        <div id='login-card-body' className='card card-body mt-5'>
          <h2 className='text-center'>Register as { this.props.employeeMode ? 'an employee' : 'a manager'}</h2>
          <Form onSubmit={this.onSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                className={
                  this.state.error_full_name
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                name='full_name'
                onChange={this.onChange}
                value={full_name}
                autoFocus
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type='text'
                className={
                  this.state.error_username
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                name='username'
                onChange={this.onChange}
                value={username}
              />
              <Form.Text className='text-muted'>
                150 characters or fewer. Letters, digits and @/./+/-/_ only.
              </Form.Text>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  className={
                    this.state.error_password
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  name='password'
                  onChange={this.onChange}
                  value={password}
                />
              </Form.Group>
              <Form.Group as={Col} className='ml-2'>
                <Form.Label>Confirm</Form.Label>
                <Form.Control
                  type='password'
                  className={
                    this.state.error_password2
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  name='password2'
                  onChange={this.onChange}
                  value={password2}
                />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Authorization Code</Form.Label>
              <Form.Control
                type='text'
                className={
                  this.state.error_code
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                name='code'
                onChange={this.onChange}
                value={code}
              />
            </Form.Group>
            <AuthAlert onAlertChange={this.handleAlertChange}/>
            <Form.Group>
              <Button type='submit' block>
                Register
              </Button>
            </Form.Group>
            <p className='mt-4'>
              Register as another account type? <Link to='/register'>Go Back</Link>
            </p>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, createMessage })(RegisterRole);