import React, { Component } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthAlert from '../components/elements/AuthAlert';
import { login } from '../store/actions/auth';

class Login extends Component {
  constructor (props) {
    super();

    this.state = {
      username: '',
      password: '',
    };
  }

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to='/' />;
    }
    const { username, password } = this.state;
    return (
      <div id='login-card' className='col-md-2 m-auto'>
        <div id='login-card-body' className='card card-body mt-5'>
          <h2 className='text-center'>Login</h2>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type='text'
                className='form-control'
                name='username'
                onChange={this.onChange}
                value={username}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                className='form-control'
                name='password'
                onChange={this.onChange}
                value={password}
                required
              />
            </Form.Group>
            <AuthAlert />
            <Form.Group as={Row} className='mt-4'>
              <Button type='submit' block>
                Login
              </Button>
            </Form.Group>
            <p className='mt-4'>
              Don't have an account? <Link to='/register'>Register</Link>
            </p>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);