import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCustomerShippingLocation } from '../../store/actions/customer_shipping_locations';

class CustomerShippingLocationDeleteModal extends Component {
  static propTypes = {
    deleteCustomerShippingLocation: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.message !== prevProps.message) {
      if (this.props.message.deleteCustomerShippingLocation) {
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.deleteCustomerShippingLocation(this.props.data.id);
  };

  render() {
    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Are you sure you want to delete this customer?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>Customer</Form.Label>
              <Col sm={6}>
                <Form.Control 
                  type='text'
                  placeholder='Customer'
                  name='name'
                  value={ this.props.data.name }
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Ship To</Form.Label>
              <Col sm={6}>
                <Form.Control 
                  type='text'
                  placeholder='Ship To' 
                  name='ship_to'
                  value={ this.props.data.ship_to }
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Location</Form.Label>
              <Col sm={6}>
                <Form.Control 
                  type='text'
                  placeholder='Location' 
                  name='location'
                  value={ this.props.data.location }
                  disabled
                />
              </Col>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant='outline-dark' type='submit' onClick={this.props.handleClose}>
            Cancel
        </Button>
        <Button variant='danger' type='submit' onClick={this.onSubmit}>
            Delete
        </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.messages,
});

export default connect(mapStateToProps, { deleteCustomerShippingLocation })(CustomerShippingLocationDeleteModal);