import React, { Component } from 'react';
import { Button, Alert } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import CustomerShippingLocationModal from '../components/elements/CustomerShippingLocationModal';
import CustomerShippingLocationDeleteModal from '../components/elements/CustomerShippingLocationDeleteModal';
import { getCustomerShippingLocations } from '../store/actions/customer_shipping_locations';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

class CustomerShippingLocationsManager extends Component {
  constructor (props) {
    super();
    
    this.state = {
      modal: false,
      editMode: false,
      delete: false,
      activeEditCustomerShippingLocation: {},
      activeDeleteCustomerShippingLocation: {},
      showResponse: false,
      response: '',
    };
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    customer_shipping_locations: PropTypes.array.isRequired,
    getCustomerShippingLocations: PropTypes.func.isRequired,
  }
  
  componentDidMount() {
    this.props.getCustomerShippingLocations();
  }

  showModal = () => this.setState({ modal: true });
  hideModal = () => this.setState({ modal: false, editMode: false });

  handleEdit = (customer_shipping_location)  => {
    this.setState({ activeEditCustomerShippingLocation: customer_shipping_location, editMode: true });
    this.showModal();
  }

  showDeleteModal = () => this.setState({ delete: true });
  hideDeleteModal = () => this.setState({ delete: false });

  handleDelete = (customer_shipping_location) => {
    this.setState({ activeDeleteCustomerShippingLocation: customer_shipping_location });
    this.showDeleteModal();
  }

  handleResponse = () => {
    const { messages } = this.props;
    
    if (messages.addCustomerShippingLocation) {
      this.setState({ response: messages.addCustomerShippingLocation });
    } else if (messages.updateCustomerShippingLocation) {
      this.setState({ response: messages.updateCustomerShippingLocation });
    } else if (messages.deleteCustomerShippingLocation) {
      this.setState({ response: messages.deleteCustomerShippingLocation });
    } else {
      this.setState({ response: 'Action was performed successfully.' });
    }

    this.setState({ showResponse: true });
    setTimeout(() => {
      this.setState({ showResponse: false });
    }, 5000);
  };
  
  render() {
    const customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total'>
        { from } - { to } of { size } entries
      </span>
    );

    const editFormatter = (cell, row) => ( 
      <Button variant='primary' size='sm' onClick={ () => { this.handleEdit(row) } } block>
        Edit
      </Button>
    );
    
    let columns = [{
      text: 'Customer',
      dataField: 'name',
      sort: true,
      order: 'asc',
      filter: textFilter(),
    }, {
      text: 'Ship To',
      dataField: 'ship_to',
    }, {
      text: 'Location',
      dataField: 'location',
    }, {
      text: 'Edit',
      dataField: 'edit',
      align: 'center',
      style: { width: '5%' },
      isDummyField: true,
      csvExport: false,
      formatter: editFormatter,
    }];

    const { isAuthenticated, user } = this.props.auth;

    if (isAuthenticated && user.role === 9) {
      const deleteFormatter = (cell, row) => ( 
        <Button variant='danger' size='sm' onClick={ () => { this.handleDelete(row) } } block>
          Delete
        </Button>
      );

      columns = columns.concat([{
        text: 'Delete',
        dataField: 'delete',
        align: 'center',
        style: { width: '5%' },
        isDummyField: true,
        csvExport: false,
        formatter: deleteFormatter,
      }],);
    }

    let products = this.props.customer_shipping_locations;

    const options = {
      custom: true,
      showTotal: true,
      sizePerPageList: [{
        text: '10', value: 10,
      }, {
        text: '25', value: 25,
      }, {
        text: '50', value: 50,
      }, {
        text: 'All', value: products.length,
      }],
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'Go to first page',
      prePageTitle: 'Go to previous page',
      firstPageTitle: 'Go to next page',
      lastPageTitle: 'Go to last page',
      totalSize: products.length,
      paginationTotalRenderer: customTotal,
    };

    const NoDataIndication = () => (
      <div className='text-center'>
        There's nothing here.
      </div>
    );

    const { ExportCSVButton } = CSVExport;

    const resultTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <ToolkitProvider
          bootstrap4
          keyField='id'
          data={ products }
          columns={ columns }
          exportCSV={{
            fileName: 'customer-shipping-locations-template.CSV',
            exportAll: true 
          }}
        >
          {toolkitprops => (
            <div>
              <div className='flex-grow-1 mt-2 mb-2'>
                <ExportCSVButton className='btn-info' { ...toolkitprops.csvProps }>Export to CSV</ExportCSVButton>
              </div>
              <div className='d-flex flex-wrap mb-3'>
                <div>
                  <SizePerPageDropdownStandalone { ...paginationProps } />
                </div>
                <div className='d-flex align-items-center ml-3 mr-auto'>
                  <PaginationTotalStandalone { ...paginationProps } />
                </div>
                <PaginationListStandalone { ...paginationProps } />
              </div>
              <BootstrapTable
                striped
                hover
                condensed
                wrapperClasses='table-responsive-md'
                defaultSortDirection='asc'
                sort={ {
                  sortCaret: (order, column) => {
                    if (!order) return (<span>&nbsp;<TiArrowUnsorted/></span>);
                    else if (order === 'asc') return (<span>&nbsp;<TiArrowSortedUp/></span>);
                    else if (order === 'desc') return (<span>&nbsp;<TiArrowSortedDown/></span>);
                    return null;
                  }
                } }
                filter={ filterFactory() }
                filterPosition='top'
                noDataIndication={() => <NoDataIndication />}
                { ...paginationTableProps }
                { ...toolkitprops.baseProps }
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );

    return (
      <div className='page'>
        <Title program={this.props.programs} />
        <div className='d-flex align-items-end'>
          <Alert className='mt-0 mr-3 mb-0 ml-auto' variant='success' show={this.state.showResponse}>{this.state.response}</Alert>
          <Button className='mt-3 ml-auto' onClick={ this.showModal }>Add Customer Shipping Location</Button>
        </div>
        <div>
          <PaginationProvider pagination={ paginationFactory(options) }>
            { resultTable }
          </PaginationProvider>
        </div>
        { this.state.modal ? <CustomerShippingLocationModal show={ this.state.modal } editMode={ this.state.editMode } data={ this.state.activeEditCustomerShippingLocation } handleClose={ this.hideModal } handleSubmit={ this.handleResponse }/> : '' }
        { this.state.delete && isAuthenticated && user.role === 9 ? <CustomerShippingLocationDeleteModal show={ this.state.delete } data={ this.state.activeDeleteCustomerShippingLocation } handleClose={ this.hideDeleteModal } handleSubmit={ this.handleResponse }/> : '' }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer_shipping_locations: state.customer_shipping_locations.customer_shipping_locations,
  messages: state.messages,
  auth: state.auth,
});

export default connect(mapStateToProps, { getCustomerShippingLocations })(CustomerShippingLocationsManager);