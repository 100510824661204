import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerRestrictedItemFormAlert from './CustomerRestrictedItemFormAlert';
import { addCustomerRestrictedItem, updateCustomerRestrictedItem } from '../../store/actions/customer_restricted_items';
import { FaEdit, FaLeaf, FaMinus } from 'react-icons/fa';

class CustomerRestrictedItemModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      ready: false,
      id: parseInt(Date.now().toString().slice(-8)),

      name: '',
      restricted_items: [{ item : '' }],

      error_name: true,
      error_restricted_items: true,
    };
  }

  static propTypes = {
    customer_restricted_items: PropTypes.array.isRequired,
    addCustomerRestrictedItem: PropTypes.func.isRequired,
    updateCustomerRestrictedItem: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { id, name, restricted_items } = this.props.data;
      var dictionary_of_restricted_items = restricted_items.split('|').map(item => {
        return({ item: item });
      });
      this.setState({ 
        id, 
        name, 
        restricted_items: dictionary_of_restricted_items,
        error_name: false,
        error_restricted_items: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.name !== prevState.name) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.restricted_items !== prevState.restricted_items) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addCustomerRestrictedItem) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          name: '',
          restricted_items: [{ item : '' }],
          error_name: true,
          error_restricted_items: true,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateCustomerRestrictedItem) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          name: '',
          restricted_items: [{ item : '' }],
          error_name: false,
          error_restricted_items: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_name && !this.state.error_restricted_items);
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputVal = e.target.value;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      this.setState({ error_name: false });
    } else {
      this.setState({ error_name: true });
    }
  }

  handleItemChange = idx => e => {
    var inputVal = e.target.value;

    const newRestrictedItems = this.state.restricted_items.map((item, item_idx) => {
      if (idx !== item_idx) return item;
      return { ...item, item: inputVal };
    });
    this.setState({ restricted_items: newRestrictedItems });

    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      this.setState({ error_restricted_items: false });
    } else {
      this.setState({ error_restricted_items: true });
    }
  };

  handleAddItem = () => {
    this.setState({
      restricted_items: this.state.restricted_items.concat([{ item: '' }])
    });
  };

  handleRemoveItem = idx => () => {
    var newRestrictedItems = this.state.restricted_items.filter((item, item_idx) => idx !== item_idx);

    if (Object.keys(newRestrictedItems).length === 0) {
      this.setState({ error_restricted_items: true });
    }
    
    this.setState({ restricted_items: newRestrictedItems });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { id, name, restricted_items } = this.state;
    
    var list_of_restricted_items = Object.keys(restricted_items).map(function(key){
        return restricted_items[key].item;
    });
    var string_list_of_restricted_items = list_of_restricted_items.filter(Boolean).join('|');
    
    const customerRestrictedItemEntry = { 
      id,
      name, 
      restricted_items: string_list_of_restricted_items
    };
    this.setState({
      error_name: false,
    });
    if (this.props.editMode) {
      this.props.updateCustomerRestrictedItem(id, customerRestrictedItemEntry);  
    } else {
      this.props.addCustomerRestrictedItem(customerRestrictedItemEntry);
    }  
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { name, restricted_items } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
          {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Customer Restricted Item</div>) : (<div className='d-flex align-items-center'><FaLeaf/>&nbsp;Add Customer Restricted Item</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Customer</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='name'
                  onChange={this.onTextChange}
                  value={ name }
                  className={
                    this.state.error_name
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <br />
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Restricted Items</Form.Label>
              <Col sm={6}>
                { restricted_items.map((item, idx) => (
                  <div className='d-flex mt-0 mb-2 ml-0 mr-0' key={idx}>
                    <Form.Control 
                      type='text'
                      maxLength='100'
                      onChange={this.handleItemChange(idx)}
                      value={ item.item }
                      className={
                        this.state.error_restricted_items
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      required
                    />
                    <Button
                      className='ml-2'
                      variant='danger'
                      onClick={this.handleRemoveItem(idx)}
                    >
                      <FaMinus/>
                    </Button>
                    <br />
                  </div>
                ))}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}></Form.Label>
              <Col sm={6}>
                <Button
                variant='primary'
                onClick={this.handleAddItem}
                >
                  Add Restricted Item
                </Button>
              </Col>
            </Form.Group>
                 
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <CustomerRestrictedItemFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  customer_restricted_items: state.customer_restricted_items.customer_restricted_items,
  message: state.messages,
});

export default connect(mapStateToProps, { addCustomerRestrictedItem, updateCustomerRestrictedItem })(CustomerRestrictedItemModal);