import { 
  GET_CUSTOMER_SHIPPING_LOCATIONS, 
  ADD_CUSTOMER_SHIPPING_LOCATION, 
  UPDATE_CUSTOMER_SHIPPING_LOCATION, 
  DELETE_CUSTOMER_SHIPPING_LOCATION, 
  CLEAR_ALL 
} from '../actions/types';

const initialState = {
  customer_shipping_locations: [],
};

export default function customerShippingLocationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_SHIPPING_LOCATIONS:
      return {
        ...state,
        customer_shipping_locations: action.payload.sort((a, b) => (a.name.toUpperCase() + ' ' + a.ship_to.toUpperCase() > b.name.toUpperCase() + ' ' + b.ship_to.toUpperCase()) ? 1 : -1),
      };
    case ADD_CUSTOMER_SHIPPING_LOCATION:
      return {
        ...state,
        customer_shipping_locations: [...state.customer_shipping_locations, action.payload].sort((a, b) => (a.name.toUpperCase() + ' ' + a.ship_to.toUpperCase() > b.name.toUpperCase() + ' ' + b.ship_to.toUpperCase()) ? 1 : -1),
      };
    case UPDATE_CUSTOMER_SHIPPING_LOCATION:
      return {
        ...state,
        customer_shipping_locations: [...state.customer_shipping_locations.filter((customer) => customer.id !== action.modified), action.payload].sort((a, b) => (a.name.toUpperCase() + ' ' + a.ship_to.toUpperCase() > b.name.toUpperCase() + ' ' + b.ship_to.toUpperCase()) ? 1 : -1),
      };
    case DELETE_CUSTOMER_SHIPPING_LOCATION:
      return {
        ...state,
        customer_shipping_locations: state.customer_shipping_locations.filter((customer) => customer.id !== action.payload),
      };
    case CLEAR_ALL:
      return {
        ...state,
        customer_shipping_locations: [],
      };
    default:
      return state;
  }
}