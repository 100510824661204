import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerFormAlert from './CustomerFormAlert';
import { addCustomer, updateCustomer } from '../../store/actions/customers';
import { FaEdit, FaAddressCard } from 'react-icons/fa';

class CustomerModal extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      ready: false,
      id: this.props.data.name,

      name: '',
      phone_number: '',
      fax_number: '',
      customer_type: 'None',
      price_level: 'Price',

      error_name: true,
      error_phone_number: false,
      error_fax_number: false,
      error_customer_type: false,
      error_price_level: false,
    };
  }

  static propTypes = {
    addCustomer: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { name, phone_number, fax_number, customer_type, price_level } = this.props.data;
      this.setState({ 
        id: name, 
        name, 
        phone_number: (phone_number === null || phone_number === 'None') ? '' : phone_number, 
        fax_number: (fax_number === null || fax_number === 'None') ? '' : fax_number, 
        customer_type: customer_type === null ? 'None' : customer_type,
        price_level: price_level === null ? 'Price' : price_level,
        error_name: false,
        error_phone_number: false,
        error_fax_number: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.name !== prevState.name) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.phone_number !== prevState.phone_number) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.fax_number !== prevState.fax_number) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.customer_type !== prevState.customer_type) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.price_level !== prevState.price_level) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addCustomer) {
        this.setState({
          ready: false,
          name: '',
          phone_number: '',
          fax_number: '',
          customer_type: 'None',
          price_level: 'Price',
          error_name: true,
          error_phone_number: false,
          error_fax_number: false,
          error_customer_type: false,
          error_price_level: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateCustomer) {
        this.setState({
          ready: false,
          name: '',
          phone_number: '',
          fax_number: '',
          customer_type: 'None',
          price_level: 'Price',
          error_name: false,
          error_phone_number: false,
          error_fax_number: false,
          error_customer_type: false,
          error_price_level: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_name && !this.state.error_phone_number && !this.state.error_fax_number && !this.state.error_customer_type && !this.state.error_price_level);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputVal = e.target.value;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      this.setState({ error_name: false });
    } else {
      this.setState({ error_name: true });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { id, name, phone_number, fax_number, customer_type, price_level } = this.state;
    const customerEntry = { 
      name, 
      phone_number: phone_number.replace(/\s/g, '').length === 0 ? 'None' : phone_number, 
      fax_number: fax_number.replace(/\s/g, '').length === 0 ? 'None' : fax_number,
      customer_type,
      price_level
    };
    this.setState({
      error_name: false,
    });
    if (this.props.editMode) {
      this.props.updateCustomer(id, customerEntry);
    } else {
      this.props.addCustomer(customerEntry);
    }
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { name, phone_number, fax_number, customer_type, price_level } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    const CUSTOMER_TYPES = [
      'None',
      'Miami',
      'Miami 2',
      'Orlando',
      'LF Delivery',
      'ITSTATE',
      'DELIVERY',
      'PICK UP',
      'SEVERS',
      'LOCAL',
      'Export',
      'Wholesale',
      'Transfor Export'
    ];

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Customer</div>) : (<div className='d-flex align-items-center'><FaAddressCard/>&nbsp;Add Customer</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Customer</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='name'
                  onChange={this.onTextChange}
                  value={ name }
                  className={
                    this.state.error_name
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Phone</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='phone_number'
                  onChange={this.onChange}
                  value={ phone_number }
                  className={
                    this.state.error_phone_number
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Fax</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='fax_number'
                  onChange={this.onChange}
                  value={ fax_number }
                  className={
                    this.state.error_fax_number
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Customer Type</Form.Label>
              <Col sm={5} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                <Form.Control 
                  as='select' 
                  name='customer_type' 
                  value={ customer_type }
                  onChange={this.onChange} 
                  required
                >
                  { CUSTOMER_TYPES.map(key => (
                    <option value={key} key={key}>{key}</option>
                  )) }
                </Form.Control>
              </Col>
            </Form.Group>

            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Price Level
                </Form.Label>
                <Col sm={10}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Price'
                    label='Price'
                    name='price_level'
                    value='Price'
                    checked={price_level === 'Price'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='MIAMI2017 Price'
                    label='MIAMI2017 Price'
                    name='price_level'
                    value='MIAMI2017 Price'
                    checked={price_level === 'MIAMI2017 Price'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='ORLA2017 Price'
                    label='ORLA2017 Price'
                    name='price_level'
                    value='ORLA2017 Price'
                    checked={price_level === 'ORLA2017 Price'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <CustomerFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.messages,
});

export default connect(mapStateToProps, { addCustomer, updateCustomer })(CustomerModal);