import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CustomerShippingLocationFormAlert extends Component {
  state = {
    show: false,
    success: false,
    type: '',
  };

  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, message } = this.props;
    
    if (error !== prevProps.error) {
      if (error.msg.message) {
        this.setState({ 
          show: true, 
          type: error.msg.message 
        });
      }
      if (error.msg.location) {
        this.setState({ 
          show: true, 
          type: error.msg.location 
        }, () => {
            this.props.onAlertChange('error_location');
        });
      }
      if (error.msg.ship_to) {
        this.setState({ 
          show: true, 
          type: error.msg.ship_to
        }, () => {
            this.props.onAlertChange('error_ship_to');
        });
      }
      if (error.msg.name) {
        this.setState({ 
          show: true, 
          type: error.msg.name 
        }, () => {
            this.props.onAlertChange('error_name');
        });
      }
    } 

    if (message !== prevProps.message) {
      if (message.addCustomerShippingLocation) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.addCustomerShippingLocation,
        });
      }
      if (message.updateCustomerShippingLocation) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.updateCustomerShippingLocation,
        });
      }
      if (message.deleteCustomerShippingLocation) {
        this.setState({ 
          show: true, 
          success: true,
          type: message.deleteCustomerShippingLocation,
        });
      }
    }
  }

  render() {
    let resultBox;

    const alertBox = (
      <Alert key={this.state.type} variant='danger'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );

    const successBox = (
      <Alert key={this.state.type} variant='success'>
        {this.state.type ? this.state.type : ''}
      </Alert>
    );
    
    if (this.state.success) {
      resultBox = successBox;
    } else {
      resultBox = alertBox
    }
    
    return (
      <Fragment>
        {this.state.show ? resultBox : ''}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(CustomerShippingLocationFormAlert);