import React, { Component } from 'react';
import { Button, Alert, Popover, OverlayTrigger, Spinner, Accordion, Card } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import DragAndDrop from '../components/elements/DragAndDrop';
import WarningModal from '../components/elements/WarningModal';
import { FaChevronDown } from 'react-icons/fa';
import { BsInfoCircle } from 'react-icons/bs';

class Validation extends Component {
  constructor (props) {
    super();
    
    this.state = {
      file: [],
      fileName: '',
      ready: false,
      uploadSuccess: false,
      wrongFiletype: false,
      results: false,
      response: '',
      errorResponse: '',
      errorResponseMessage: '',
      dateAlert: '',
      modal: false,
      loaded: true,
    };
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  handleDrop = (newFileList) => {
    let fileName = newFileList[0].name;
    let sections = fileName.split('.');
    if (sections[sections.length - 1] === 'CSV') {
      this.stageFile(true, newFileList[0], fileName);
    } else {
      this.stageFile(false, '');
    }
  }

  handleClick = (e) => {
    this.refs.fileUploader.click();
  }

  fileInputClicked = (e) => {
    e.target.value = '';
  }

  fileInputChanged = (e) => {
    if (document.getElementById('file').files[0] !== undefined) {
      let file = document.getElementById('file').files[0];
      let fileName = file.name;
      let sections = fileName.split('.');
      if (sections[sections.length - 1] === 'CSV') {
        this.stageFile(true, file, fileName);
      } else {
        this.stageFile(false);
      }
    }
  }

  stageFile = (success, file, fileName) => {
    if (success) {
      this.setState({file: []});
      this.setState(prevState => ({
        file: prevState.file.concat(file)
      }));
      this.setState({fileName: fileName, ready: true, wrongFiletype: false});
    } else {
      this.setState({file: [], ready: false, wrongFiletype: true});
    }
  }

  async uploadFile() {
    const promises = [];
    promises.push(this.sendRequest(this.state.file[0]));
    try {
      await Promise.all(promises);
      this.setState({ uploadSuccess: true });
    } catch (e) {
      this.setState({ uploadSuccess: false, response: '', errorResponse: 'BAD FILE UPLOAD', results: true });
    }
  }

  sendRequest = (file) => {
    const { user } = this.props.auth;
    const req = new XMLHttpRequest();
    var promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('file', file);
      formData.append('user', user.username);
      formData.append('script', 'VALIDATION');
      req.addEventListener('load', () => {
        if (req.status === 200) {
          this.setState({ loaded: true, response: req.response, errorResponse: '', errorResponseMessage: '', results: true, modal: true });
        } else if (req.status === 422) {
          this.setState({ loaded: true, response: '', errorResponse: 'WRONG FORMAT', errorResponseMessage: req.response, results: true });
        } else if (req.status === 206) {
          this.setState({ loaded: true, response: req.response, errorResponse: 'ONLY DATE WARNING', results: true, modal: true });
        } else if (req.status === 204) {
          this.setState({ loaded: true, response: '', errorResponse: 'NO ISSUES', results: true });
        } else if (req.status === 409) {
          this.setState({ loaded: true, response: '', errorResponse: 'MISSING INFO', errorResponseMessage: req.response, results: true });
        } else if (req.status === 500) {
          this.setState({ loaded: true, response: '', errorResponse: 'SERVER ERROR', results: true });
        } else {
          this.setState({ loaded: true, response: '', errorResponse: 'OTHER ERROR', results: true });
        }
      })
      req.open('POST', `${process.env.REACT_APP_API_URL}/upload/`);
      req.send(formData);
      this.setState({ loaded: false });
    });

    return promise;
  }

  displayResults = () => {
    this.uploadFile();
    this.setState({
      ready: false,
      uploadSuccess: false,
      wrongFiletype: false,
    });
  }

  showModal = () => this.setState({ modal: true });
  hideModal = () => this.setState({ modal: false });

  render() {
    const activeButton = (<Button variant='primary' onClick={this.displayResults}>VALIDATE</Button>);
    const disabledButton = (<Button variant='secondary' disabled>VALIDATE</Button>);

    const stagedFile = (<Alert key='stagedFile' variant='success'>{this.state.fileName}</Alert>);
    const warningMessage = (<Alert key='wrongFiletype' variant='danger'>Wrong filetype. Please try again.</Alert>);

    const wrongFormatAlert = (<Alert key='wrongFormat' variant='danger'>Wrong format inside CSV. Please try again.<br />{this.state.errorResponseMessage}</Alert>);
    const noIssuesAlert = (
      <div className='text-center'>
        <img src='/icons/confetti.png' width='256' height='256' alt='Confetti' />
        <div className='mt-4 text-secondary no-result-text'>No duplicates, price mismatches, missing required customers, or restricted customers/items found. Congratulations!</div>
      </div>
    );
    let missingInfoAlert = (<Alert key='missingInfo' variant='danger'><div dangerouslySetInnerHTML={{ __html: this.state.errorResponseMessage }} /></Alert>);
    const serverErrorAlert = (<Alert key='serverError' variant='danger'>500 Error: Something went wrong in the server. Please contact your administrator.</Alert>);
    const otherErrorAlert = (<Alert key='otherError' variant='danger'>Something went wrong. Please contact your administrator.</Alert>);
    const badFileUploadAlert = (<Alert key='badFileUpload' variant='danger'>Something went wrong uploading the file. Please contact your administrator.</Alert>);

    let errorResult;
    var errorResponse = this.state.errorResponse;
    if (errorResponse === 'WRONG FORMAT') {
      errorResult = wrongFormatAlert;
    } else if (errorResponse === 'NO ISSUES' || errorResponse === 'ONLY DATE WARNING') {
      errorResult = noIssuesAlert;
    } else if (errorResponse === 'MISSING INFO') {
      errorResult = missingInfoAlert;
    } else if (errorResponse === 'SERVER ERROR') {
      errorResult = serverErrorAlert;
    } else if (errorResponse === 'OTHER ERROR') {
      errorResult = otherErrorAlert;
    } else if (errorResponse === 'BAD FILE UPLOAD') {
      errorResult = badFileUploadAlert;
    }

    let modal;
    var resultHtml = this.state.response.split('<br />');
    var dateWarning = resultHtml[0];

    if (dateWarning.includes('Date Warning: Monday')) {
      modal = <WarningModal show={ this.state.modal } handleClose={ this.hideModal } day='Monday' />
    } else if (dateWarning.includes('Date Warning: Tuesday')) {
      modal = <WarningModal show={ this.state.modal } handleClose={ this.hideModal } day='Tuesday' />
    } else if (dateWarning.includes('Date Warning: Thursday')) {
      modal = <WarningModal show={ this.state.modal } handleClose={ this.hideModal } day='Thursday' />
    } else if (dateWarning.includes('Date Warning: Friday')) {
      modal = <WarningModal show={ this.state.modal } handleClose={ this.hideModal } day='Friday' />
    }

    let accordions = [
      { id: 1, header: resultHtml[1], body: resultHtml[2] },
      { id: 2, header: resultHtml[3], body: resultHtml[4] },
      { id: 3, header: resultHtml[5], body: resultHtml[6] },
      { id: 4, header: resultHtml[7], body: resultHtml[8] },
      { id: 5, header: resultHtml[9], body: resultHtml[10] }
    ];

    var tableResult = (
      <Accordion defaultActiveKey={0}>
        {accordions.map(card => (
          <Card key={ card.id }>
            <Accordion.Toggle as={Card.Header} className='h5 result-table-card-header' eventKey={card.id}>
              <FaChevronDown /> {card.header}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={card.id}>
              <Card.Body><div dangerouslySetInnerHTML={{ __html: card.body }} /></Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    )

    const popover = (
      <Popover>
        <Popover.Title as='h3'>Memorized Liam Report</Popover.Title>
        <Popover.Content>
          Use <strong>Sales Report</strong> from QB.<br />
          Headers should include: 
          <ul className='mb-0'>
            <li>Date</li>
            <li>Num</li>
            <li>Memo</li>
            <li>Name</li>
            <li>Item</li>
            <li>Qty</li>
            <li>Sales Price</li>
            <li>Ship To Address 1</li>
          </ul>
        </Popover.Content>
      </Popover>
    );    

    return (
      <div className='page'>
        <Title program={ this.props.programs } />
        <DragAndDrop handleDrop={ this.handleDrop }>
          <div className='drag-target'>
            <div id='clickable-area' onClick={ this.handleClick }>
              <div className='drag-target-text' id='instruction'>
                Drag and drop your .CSV file,<br />
                or click here to select a file.
              </div>
              <input type='file' id='file' accept='.CSV' ref='fileUploader' onClick={ this.fileInputClicked } onChange={ this.fileInputChanged } className='hidden'/>
            </div>
          </div>
        </DragAndDrop>
        <br />
        <div className='uploaded-file-stage'>
          <div className='uploaded-file-header'>
            <h3>Uploaded File:</h3>
            <div className='uploaded-file-info'>
              <OverlayTrigger 
                placement='right'
                delay={{ show: 250, hide: 1000 }}
                overlay={popover}
              >
                <Button
                  variant='light'
                  className='d-inline-flex align-items-center'
                >
                  <BsInfoCircle />
                </Button>
              </OverlayTrigger>
            </div>
          </div>          
          {(this.state.ready || this.state.results) && !this.state.wrongFiletype ? stagedFile : ''}
          {this.state.wrongFiletype ? warningMessage : ''}
        </div>
        <div className='uploaded-file-buttons'>
          {this.state.ready ? activeButton : disabledButton}
        </div>
        <div className='uploaded-file-results'>
          {this.state.loaded ? '' :
            <Spinner animation='border' role='status' variant='info' className='d-block m-auto'>
              <span className='sr-only'>Loading...</span>
            </Spinner> 
          }
          {this.state.results && this.state.response && this.state.loaded ? tableResult : ''}
          {this.state.results && this.state.errorResponse && this.state.loaded ? errorResult : ''}
        </div>
        {modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Validation);