import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_ITEMS, 
  ADD_ITEM, 
  UPDATE_ITEM, 
  DELETE_ITEM 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'items';

// GET ITEMS
export const getItems = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD ITEM
export const addItem = (item) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, item, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addItem: 'Item Added' }));
      dispatch({
        type: ADD_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE ITEM
export const updateItem = (id, item) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, item, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateItem: 'Item Updated' }));
      dispatch({
        type: UPDATE_ITEM,
        modified: id,
        payload: res.data,
      });

      if (id !== item.item) {
        axios
          .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
          .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
      }
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// DELETE ITEM
export const deleteItem = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteItem: 'Item Deleted' }));
      dispatch({
        type: DELETE_ITEM,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};