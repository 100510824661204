import React from 'react';

function parseCsvToRowsAndColumn(csvText) {
  const rows = csvText.split('\n');
  if (!rows || rows.length === 0) {
    return [];
  }
  
  let matchCommas = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/g;
  let matchDoubleQuote = /"/g;
  
  let modifiedRows = rows.map(row => row.replace(matchCommas, ';'));
  modifiedRows = modifiedRows.map(row => row.replace(matchDoubleQuote, ''));

  return modifiedRows.map(row => row.split(';'));
}

function groupByCount(rowsWithColumns) {
  let newRows = rowsWithColumns;
  let indexReference = {};

  let currentGroup = '';
  let currentCount = 0;
  for (let i = 0; i < rowsWithColumns.length; i++) {
    if (rowsWithColumns[i][0] !== currentGroup) {
      if (currentCount) {
        indexReference[i - currentCount] = currentCount;
      }
      currentGroup = rowsWithColumns[i][0];
      currentCount = 1;
    } else {
      currentCount += 1;
      newRows[i] = newRows[i].slice(1);
    }
  }
  indexReference[rowsWithColumns.length - currentCount] = currentCount;
  return { indexReference, newRows };
}

const CsvToHtmlTable = ({ data, hasHeader, tableClassName, tableRowClassName, tableColumnClassName, rowKey, colKey, groupBy, withTotals }) => {
  const rowsWithColumns = parseCsvToRowsAndColumn(data.trim());
  let headerRow = undefined;
    if (hasHeader) {
    headerRow = rowsWithColumns.splice(0, 1)[0];
  }

  const renderTableHeader = (row) => {
    if (row && row.map) {
      return (
        <thead>
        <tr>
          {row.map((column, i) => (
            <th key={`header-${i}`}>
              {column}
            </th>
          ))}
        </tr>
        </thead>
      );
    }
  };

  const renderTableBody = (rows) => {
    if (rows && rows.map) {
      return (
        <tbody>
          {rows.map((row, rowIdx) => (
            <tr className={tableRowClassName} key={typeof(rowKey) === 'function' ? rowKey(row, rowIdx) : rowIdx}>
              {row.map && row.map((column, colIdx) => (
                <td
                className={tableColumnClassName}
                key={typeof(rowKey) === 'function' ? colKey(row, colIdx, rowIdx) : column[colKey]}
                >
                {column}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  const groupByRenderTableBody = (rows) => {
    let { indexReference, newRows } = groupByCount(rows);
    
    if (newRows && newRows.map) {
      return (
        <tbody>
          {rows.map((row, rowIdx) => (
            <tr className={withTotals && row[0] === 'TOTAL' ? 'table-total' : tableRowClassName} key={typeof(rowKey) === 'function' ? rowKey(row, rowIdx) : rowIdx}>

              {row.map && row.map((column, colIdx) => (colIdx === 0) ? 
                (
                  <th 
                  className={tableColumnClassName} 
                  rowSpan={indexReference[rowIdx]}
                  valign='top'
                  key={typeof(rowKey) === 'function' ? colKey(row, colIdx, rowIdx) : column[colKey]}
                  >
                  {column}
                  </th>
                ) : 
                
                (
                  <td
                    className={tableColumnClassName}
                    key={typeof(rowKey) === 'function' ? colKey(row, colIdx, rowIdx) : column[colKey]}
                  >
                  {column}
                  </td>
                )
              )}

            </tr>
          ))}
        </tbody>
      );
    }
  }

  return (
    <table className={`csv-html-table ${tableClassName}`}>
      {renderTableHeader(headerRow)}
      {groupBy ? groupByRenderTableBody(rowsWithColumns) : renderTableBody(rowsWithColumns)}
    </table>
  );
};

CsvToHtmlTable.defaultProps = {
  data: '',
  rowKey: (row, rowIdx) => `row-${rowIdx}`,
  colKey: (col, colIdx, rowIdx) => `col-${colIdx}`,
  hasHeader: true,
  tableClassName: '',
  tableRowClassName: '',
  tableColumnClassName: '',
  groupBy: false,
  withTotals: false,
};

export default CsvToHtmlTable;