import { 
  GET_REPORTS, 
  GET_OUTPUT, 
  CLEAR_ALL 
} from '../actions/types';

const initialState = {
  date: '',
  script: '',
  owner: '',
  result: '',
  input: '',
  report: '',
  output: '',
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        date: action.payload.date,
        script: action.payload.script,
        owner: action.payload.owner,
        result: action.payload.result,
        input: action.payload.input,
        report: action.payload.report,
      };
    case GET_OUTPUT:
      return {
        ...state,
        output: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        date: '',
        script: '',
        owner: '',
        result: '',
        input: '',
        report: '',
        output: '',
      };
    default:
      return state;
  }
}