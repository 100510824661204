import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RestrictedItemLocationFormAlert from './RestrictedItemLocationFormAlert';
import { addRestrictedItemLocation, updateRestrictedItemLocation } from '../../store/actions/restricted_item_locations';
import { FaEdit, FaLeaf } from 'react-icons/fa';

class RestrictedItemLocationModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      ready: false,
      id: parseInt(Date.now().toString().slice(-8)),

      item: '',
      restricted_locations: [],

      error_item: true,
      error_restricted_locations: true,
    };
  }

  static propTypes = {
    restricted_item_locations: PropTypes.array.isRequired,
    addRestrictedItemLocation: PropTypes.func.isRequired,
    updateRestrictedItemLocation: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { id, item, restricted_locations } = this.props.data;
      this.setState({ 
        id, 
        item, 
        restricted_locations: restricted_locations.split('|'),
        error_item: false,
        error_restricted_locations: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.item !== prevState.item) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.restricted_locations !== prevState.restricted_locations) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addRestrictedItemLocation) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          item: '',
          restricted_locations: [],
          error_item: true,
          error_restricted_locations: true,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateRestrictedItemLocation) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          item: '',
          restricted_locations: [],
          error_item: false,
          error_restricted_locations: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_item && !this.state.error_restricted_locations);
  }

  toggleLocation = e => {
    var stateName = e.target.value;
    var newLocations = this.state.restricted_locations;
    var index = newLocations.indexOf(stateName);

    if (index > -1) {
      newLocations = newLocations.filter(loc => loc !== stateName);
      this.setState({ restricted_locations: newLocations });
    } else {
      newLocations = newLocations.concat([stateName]);
      this.setState( { restricted_locations: newLocations } );
    }

    if (newLocations.length === 0) {
      this.setState({ error_restricted_locations: true });
    } else {
      this.setState({ error_restricted_locations: false });
    }
  };

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputVal = e.target.value;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      this.setState({ error_item: false });
    } else {
      this.setState({ error_item: true });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { id, item, restricted_locations } = this.state;

    var string_list_of_locations = restricted_locations.join('|');
    
    const restrictedItemLocationEntry = { 
      id,
      item, 
      restricted_locations: string_list_of_locations
    };
    this.setState({
      error_item: false,
    });
    if (this.props.editMode) {
      this.props.updateRestrictedItemLocation(id, restrictedItemLocationEntry);  
    } else {
      this.props.addRestrictedItemLocation(restrictedItemLocationEntry);
    }  
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { item } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    const US_STATES = {
      'FL': 'Florida',
      'AL': 'Alabama',
      'AK': 'Alaska',
      'AZ': 'Arizona',
      'AR': 'Arkansas',
      'CA': 'California',
      'CO': 'Colorado',
      'CT': 'Connecticut',
      'DE': 'Delaware',
      'GA': 'Georgia',
      'HI': 'Hawaii',
      'ID': 'Idaho',
      'IL': 'Illinois',
      'IN': 'Indiana',
      'IA': 'Iowa',
      'KS': 'Kansas',
      'KY': 'Kentucky',
      'LA': 'Louisiana',
      'ME': 'Maine',
      'MD': 'Maryland',
      'MA': 'Massachusetts',
      'MI': 'Michigan',
      'MN': 'Minnesota',
      'MS': 'Mississippi',
      'MO': 'Missouri',
      'MT': 'Montana',
      'NE': 'Nebraska',
      'NV': 'Nevada',
      'NH': 'New Hampshire',
      'NJ': 'New Jersey',
      'NM': 'New Mexico',
      'NY': 'New York',
      'NC': 'North Carolina',
      'ND': 'North Dakota',
      'OH': 'Ohio',
      'OK': 'Oklahoma',
      'OR': 'Oregon',
      'PA': 'Pennsylvania',
      'RI': 'Rhode Island',
      'SC': 'South Carolina',
      'SD': 'South Dakota',
      'TN': 'Tennessee',
      'TX': 'Texas',
      'UT': 'Utah',
      'VT': 'Vermont',
      'VA': 'Virginia',
      'WA': 'Washington',
      'WV': 'West Virginia',
      'WI': 'Wisconsin',
      'WY': 'Wyoming'
    };

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
          {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Restricted Item Location</div>) : (<div className='d-flex align-items-center'><FaLeaf/>&nbsp;Add Restricted Item Location</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Restricted Item</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='item'
                  onChange={this.onTextChange}
                  value={ item }
                  className={
                    this.state.error_item
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Locations
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  { Object.keys(US_STATES).map(key => (
                    <Form.Check
                      inline
                      type='checkbox'
                      className='mr-0'
                      id={key}
                      key={key}
                      label={ US_STATES[key] }
                      name={key}
                      value={key}
                      checked={this.state.restricted_locations.indexOf(key) > -1}
                      onChange={this.toggleLocation}
                    />
                  )) }                  
                </Col>
              </Form.Group>
            </fieldset>           
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <RestrictedItemLocationFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  restricted_item_locations: state.restricted_item_locations.restricted_item_locations,
  message: state.messages,
});

export default connect(mapStateToProps, { addRestrictedItemLocation, updateRestrictedItemLocation })(RestrictedItemLocationModal);