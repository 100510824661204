import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_REQUIRED_CUSTOMERS, 
  ADD_REQUIRED_CUSTOMER, 
  UPDATE_REQUIRED_CUSTOMER, 
  DELETE_REQUIRED_CUSTOMER 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'required_customers';

// GET REQUIRED CUSTOMERS
export const getRequiredCustomers = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_REQUIRED_CUSTOMERS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD REQUIRED CUSTOMER
export const addRequiredCustomer = (required_customer) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, required_customer, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addRequiredCustomer: 'Required Customer Added' }));
      dispatch({
        type: ADD_REQUIRED_CUSTOMER,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE REQUIRED CUSTOMER
export const updateRequiredCustomer = (id, required_customer) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, required_customer, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateRequiredCustomer: 'Required Customer Updated' }));
      dispatch({
        type: UPDATE_REQUIRED_CUSTOMER,
        modified: id,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// DELETE REQUIRED CUSTOMER
export const deleteRequiredCustomer = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteRequiredCustomer: 'Required Customer Deleted' }));
      dispatch({
        type: DELETE_REQUIRED_CUSTOMER,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};