import axios from 'axios';
import { returnErrors } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_DISPATCH,
  GET_24H_DISPATCHES, 
  GET_OUTPUT,
  CLEAR_ALL
} from './types';

const MAIN_URL = process.env.REACT_APP_API_URL;
const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export const getSpecificDispatch = (scriptType, date, data) => {
  var dispatchIndex = null;
  for (var i = data.length - 1; i >= 0; --i) {
    if (data[i].script === scriptType && !data[i].result.includes('INVALID') && !data[i].result.includes('ERROR')) {
      if (new Date(data[i].date).getTime() === new Date(date).getTime()) {
        dispatchIndex = i;
        break;
      }
    }
  }
  
  let dispatch = 'NO DISPATCHES';

  if (dispatchIndex === null) {
    return {
      date: '',
      script: '',
      owner: '',
      result: '',
      input: '',
      dispatch: dispatch,
    };
  }

  if (data[dispatchIndex].result.includes('CONFIRMED')) {
    let dispatchName = data[dispatchIndex].output.split('/media/')[1];
    dispatch = `${MAIN_URL}/media/${dispatchName}`;
  }
  let inputName = data[dispatchIndex].file.split('/media/')[1];
  
  let specificDispatch = {
    date: data[dispatchIndex].date,
    script: data[dispatchIndex].script,
    owner: data[dispatchIndex].owner,
    result: data[dispatchIndex].result,
    input: `${MAIN_URL}/media/${inputName}`,
    dispatch: dispatch,
  };
  return specificDispatch;
};

export const diffHours = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60);


  return Math.abs(Math.round(diff));
}

export const get24H = (scriptType, data) => {
  var dispatches = [];
  const start = new Date(Date.now()); 
  
  for (var i = data.length - 1; i >= 0; --i) {
    if (data[i].script === scriptType && !data[i].result.includes('INVALID') && !data[i].result.includes('ERROR')) {
      var dataDate = new Date(data[i].date);
      if (diffHours(start, dataDate) <= 24) {
        dispatches.push(data[i].date);
      } else {
        break;
      }
    }
  }

  if (dispatches.length === 0) {
    return 'NO DISPATCHES';
  }
  return dispatches.toString();
};


// GET DISPATCH
export const getDispatch = (scriptType, date) => (dispatch, getState) => {
  axios
    .get(`${API_URL}/dispatches/`, tokenConfig(getState))
    .then((res) => {
      let specific_dispatch = getSpecificDispatch(scriptType, date, res.data);
      dispatch({
        type: CLEAR_ALL,
      });
      dispatch({
        type: GET_DISPATCH,
        payload: specific_dispatch,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// GET 24H DISPATCHES
export const get24HDispatches = (scriptType) => (dispatch, getState) => {
  axios
    .get(`${API_URL}/dispatches/`, tokenConfig(getState))
    .then((res) => {
      let dispatch_dates = get24H(scriptType, res.data);
      dispatch({
        type: GET_24H_DISPATCHES,
        payload: dispatch_dates,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// GET OUTPUT
export const getOutput = (fileURL) => (dispatch, getState) => {
  axios
    .get(fileURL, tokenConfig(getState))
    .then((res) => {
      let table_text = res.data.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '');
      let table_text_remove_inner_commas = table_text.replace(/,(?=[^"]*"[^"]*(?:"[^"]*"[^"]*)*$)/gm, '');
      let table_text_cleaned = table_text_remove_inner_commas.replace(/"/gm, '');
      dispatch({
        type: GET_OUTPUT,
        payload: table_text_cleaned,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
