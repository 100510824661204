import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerShippingLocationFormAlert from './CustomerShippingLocationFormAlert';
import { addCustomerShippingLocation, updateCustomerShippingLocation } from '../../store/actions/customer_shipping_locations';
import { FaEdit, FaAddressCard } from 'react-icons/fa';

class CustomerShippingLocationModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      ready: false,
      id: parseInt(Date.now().toString().slice(-8)),

      name: '',
      ship_to: '',
      location: '',

      error_name: true,
      error_ship_to: true,
      error_location: true,
    };
  }

  static propTypes = {
    addCustomerShippingLocation: PropTypes.func.isRequired,
    updateCustomerShippingLocation: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { id, name, ship_to, location } = this.props.data;
      this.setState({ 
        id, 
        name, 
        ship_to,
        location,
        error_name: true,
        error_ship_to: true,
        error_location: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.name !== prevState.name) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.ship_to !== prevState.ship_to) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.location !== prevState.location) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addCustomerShippingLocation) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          name: '',
          ship_to: '',
          location: '',
          error_name: true,
          error_ship_to: true,
          error_location: true,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateCustomerShippingLocation) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          name: '',
          ship_to: '',
          location: '',
          error_name: true,
          error_ship_to: true,
          error_location: true,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_name && !this.state.error_ship_to && !this.state.error_location);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.value === 'None' || e.target.value === '') {
      this.setState({ error_location: true });
    } else {
      this.setState({ error_location: false });
    }
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputName = e.target.name;
    var inputVal = e.target.value;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      if (inputName === 'name') {
        this.setState({ error_name: false });
      } else if (inputName === 'ship_to') {
        this.setState({ error_ship_to: false });
      }
    } else {
      if (inputName === 'name') {
        this.setState({ error_name: true });
      } else if (inputName === 'ship_to') {
        this.setState({ error_ship_to: true });
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { id, name, ship_to, location } = this.state;
    const customerShippingLocationEntry = { 
      id,
      name, 
      ship_to,
      location
    };
    this.setState({
      error_name: false,
    });
    if (this.props.editMode) {
      this.props.updateCustomerShippingLocation(id, customerShippingLocationEntry);
    } else {
      this.props.addCustomerShippingLocation(customerShippingLocationEntry);
    }
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { name, ship_to, location } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    const US_STATES = {
      'FL': 'Florida',
      'AL': 'Alabama',
      'AK': 'Alaska',
      'AZ': 'Arizona',
      'AR': 'Arkansas',
      'CA': 'California',
      'CO': 'Colorado',
      'CT': 'Connecticut',
      'DC': 'Washington D.C.',
      'DE': 'Delaware',
      'GA': 'Georgia',
      'HI': 'Hawaii',
      'ID': 'Idaho',
      'IL': 'Illinois',
      'IN': 'Indiana',
      'IA': 'Iowa',
      'KS': 'Kansas',
      'KY': 'Kentucky',
      'LA': 'Louisiana',
      'ME': 'Maine',
      'MD': 'Maryland',
      'MA': 'Massachusetts',
      'MI': 'Michigan',
      'MN': 'Minnesota',
      'MS': 'Mississippi',
      'MO': 'Missouri',
      'MT': 'Montana',
      'NE': 'Nebraska',
      'NV': 'Nevada',
      'NH': 'New Hampshire',
      'NJ': 'New Jersey',
      'NM': 'New Mexico',
      'NY': 'New York',
      'NC': 'North Carolina',
      'ND': 'North Dakota',
      'OH': 'Ohio',
      'OK': 'Oklahoma',
      'OR': 'Oregon',
      'PA': 'Pennsylvania',
      'RI': 'Rhode Island',
      'SC': 'South Carolina',
      'SD': 'South Dakota',
      'TN': 'Tennessee',
      'TX': 'Texas',
      'UT': 'Utah',
      'VT': 'Vermont',
      'VA': 'Virginia',
      'WA': 'Washington',
      'WV': 'West Virginia',
      'WI': 'Wisconsin',
      'WY': 'Wyoming'
    };

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Customer Shipping Location</div>) : (<div className='d-flex align-items-center'><FaAddressCard/>&nbsp;Add Customer Shipping Location</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Customer</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='name'
                  onChange={this.onTextChange}
                  value={ name }
                  className={
                    this.state.error_name
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Ship To</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='ship_to'
                  onChange={this.onTextChange}
                  value={ ship_to }
                  className={
                    this.state.error_ship_to
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                />
              </Col>
            </Form.Group>
            
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Location</Form.Label>
              <Col sm={5} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                <Form.Control 
                  as='select' 
                  name='location' 
                  value={ location }
                  onChange={this.onChange} 
                  className={
                    this.state.error_location
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                > 
                  <option value='None'>None</option>
                  { Object.keys(US_STATES).map(key => (
                    <option value={key} key={key}>{US_STATES[key]} ({key})</option>
                  )) }
                </Form.Control>
              </Col>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
        <CustomerShippingLocationFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  customer_shipping_locations: state.customer_shipping_locations.customer_shipping_locations,
  message: state.messages,
});

export default connect(mapStateToProps, { addCustomerShippingLocation, updateCustomerShippingLocation })(CustomerShippingLocationModal);