import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import CsvToHtmlTable from '../components/common/CsvToHtmlTable';
import { getReports, getOutput } from '../store/actions/reports';
import moment from 'moment';

class Report extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      loaded: true,
      output: 'NONE',
      scriptType: '',
      reportTitle: '',
      result: false,
    };
  }

  static propTypes = {
    report: PropTypes.string.isRequired,
    output: PropTypes.string.isRequired,
    getReports: PropTypes.func.isRequired,
    getOutput: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { output } = this.props;
    this.setState({ output });
  }

  componentDidUpdate(prevProps, prevState) {
    const { report, output } = this.props;
    if (report !== prevProps.report) {
      if (report !== 'NO REPORTS' || report !== 'NO DUPS' || report !== 'NO MISMATCHES' ||  report !== 'NO PRICE LIST' || report !== 'NO FREIGHT') {
        this.props.getOutput(this.props.report);
      }
    }
    if (this.state.scriptType !== prevState.scriptType) {
      this.setState({ output, loaded: false });
    }
    if (output !== prevProps.output || report !== prevProps.report) {
      this.setState({ output });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 100);
    }
  }

  showOutput = (e) => {
    this.props.getReports(e.target.id);

    if (e.target.id === 'VALIDATION - DUPS') {
      this.setState({scriptType: 'VALIDATION - DUPS', reportTitle: 'Duplicates Report'});
    } else if (e.target.id === 'VALIDATION - MISMATCHES') {
      this.setState({scriptType: 'VALIDATION - MISMATCHES', reportTitle: 'Price Check Report'});
    } else if (e.target.id === 'FREIGHT - MIAMI') {
      this.setState({scriptType: 'FREIGHT - MIAMI', reportTitle: 'Miami Freight Report'});
    } else if (e.target.id === 'FREIGHT - MIAMI 2') {
      this.setState({scriptType: 'FREIGHT - MIAMI 2', reportTitle: 'Miami 2 Freight Report'});
    } else if (e.target.id === 'FREIGHT - LF DELIVERY') {
      this.setState({scriptType: 'FREIGHT - LF DELIVERY', reportTitle: 'LF Delivery Freight Report'});
    } else if (e.target.id === 'FREIGHT - ORLANDO') {
      this.setState({scriptType: 'FREIGHT - ORLANDO', reportTitle: 'Orlando Freight Report'});
    } else if (e.target.id === 'PRICE LIST') {
      this.setState({scriptType: 'PRICE LIST', reportTitle: 'Price List'});
    } 

    this.setState({ result: true });
  }

  render() {
    let result;
    if (this.state.result) {
      if (this.props.report === 'NO REPORTS') {
        result = (
          <div className='loading'>
            <hr></hr>
            <h3 className='mb-4'>{this.state.reportTitle}</h3>
            <h5 className='mb-4'>
              No reports have been submitted.
            </h5>
          </div>
        );
      } else if (this.props.report === 'NO DUPS' || this.props.report === 'NO MISMATCHES' || this.props.report === 'NO FREIGHT' || this.props.report === 'NO PRICE LIST') {
        let formattedDate = 'Weekday, Month Date Year, Hour:Minute';
        formattedDate = moment(this.props.date).format('dddd, MMMM Do YYYY, h:mm a');
        let resultString = 'NO DUPS';
        if (this.props.report === 'NO MISMATCHES' ) {
          resultString = 'NO MISMATCHES';
        } else if (this.props.report === 'NO FREIGHT' ) {
          resultString = 'NO FREIGHT CALCULATED';
        } else if (this.props.report === 'NO PRICE LIST') {
          resultString = 'NO PRICES UPDATED';
        }

        result = (
        <div className='loading'>
          <hr></hr>
          <h3 className='mb-4'>{this.state.reportTitle}</h3>
          <h5 className='mb-4'>
            Submitted on {formattedDate} by <u>{this.props.owner}</u> with <u>{resultString}</u>.
          </h5>
          <div className='mb-4'>
            <a href={this.props.input} rel='noopener noreferrer' target='_blank'>
              <Button variant='info'>
                Download Input File
              </Button>
            </a>
          </div>
        </div>
        );
      } else {
        let formattedDate = 'Weekday, Month Date Year, Hour:Minute';
        formattedDate = moment(this.props.date).format('dddd, MMMM Do YYYY, h:mm a');

        result = (
        <div className='loading'>
          <hr></hr>
          <h3 className='mb-4'>{this.state.reportTitle}</h3>
          <h5 className='mb-4'>
            Submitted on {formattedDate} by <u>{this.props.owner}</u> with <u>{this.props.result}</u>.
          </h5>
          <div className='mb-4'>
            <a href={this.props.input} rel='noopener noreferrer'>
              <Button variant='info'>
                Download Input File
              </Button>
            </a>
            {' '}
            <a href={this.props.report} rel='noopener noreferrer' target='_blank'>
              <Button variant='info'>
              Output file (shown below)
              </Button>
            </a>
          </div>
          <CsvToHtmlTable 
            data={this.state.output} 
            groupBy={this.state.scriptType.includes('VALIDATION') ? false : true} 
            withTotals={this.state.scriptType.includes('VALIDATION') ? false : true}  
            tableClassName='table table-sm table-striped table-bordered table-hover table-responsive-sm loading'
          />
        </div>
        );
      }
    }

    return (
      <div className='page'>
        <Title program={this.props.programs} />
        <br />
        <div className='mt-2'>
          <div className='mb-5'>
            <Button
              className='mb-2 mr-2'
              id='VALIDATION - DUPS'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'VALIDATION - DUPS'}>
                Duplicates Report
            </Button>
            <Button
              className='mb-2 mr-2'
              id='VALIDATION - MISMATCHES'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'VALIDATION - MISMATCHES'}>
                Price Check Report
            </Button>
            <Button
              className='mb-2 mr-2'
              id='FREIGHT - MIAMI'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'FREIGHT - MIAMI'}>
                MIA Freight Report
            </Button>
            <Button
              className='mb-2 mr-2'
              id='FREIGHT - MIAMI 2'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'FREIGHT - MIAMI 2'}>
                MIA 2 Freight Report
            </Button>
            <Button
              className='mb-2 mr-2'
              id='FREIGHT - LF DELIVERY'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'FREIGHT - LF DELIVERY'}>
                LF Delivery Freight Report
            </Button>
            <Button
              className='mb-2 mr-2'
              id='FREIGHT - ORLANDO'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'FREIGHT - ORLANDO'}>
                ORL Freight Report
            </Button>
            <Button
              className='mb-2'
              id='PRICE LIST'
              onClick={this.showOutput}
              variant='outline-primary'
              size='lg'
              active={this.state.scriptType === 'PRICE LIST'}>
                Price List
            </Button>
          </div>
          {this.state.loaded ? result : 
            <Spinner animation='border' role='status' variant='info' className='d-block m-auto'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  date: state.reports.date,
  owner: state.reports.owner,
  result: state.reports.result,
  input: state.reports.input,
  report: state.reports.report,
  output: state.reports.output
});

export default connect(mapStateToProps, { getReports, getOutput })(Report);