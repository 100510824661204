import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RequiredCustomerFormAlert from './RequiredCustomerFormAlert';
import { addRequiredCustomer, updateRequiredCustomer } from '../../store/actions/required_customers';
import { FaEdit, FaLeaf } from 'react-icons/fa';

class RequiredCustomerModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      ready: false,
      id: parseInt(Date.now().toString().slice(-8)),

      name: '',
      monday: 'No',
      tuesday: 'No',
      thursday: 'No',
      friday: 'No',

      error_name: true,
      error_monday: false,
      error_tuesday: false,
      error_thursday: false,
      error_friday: false,
    };
  }

  static propTypes = {
    required_customers: PropTypes.array.isRequired,
    addRequiredCustomer: PropTypes.func.isRequired,
    updateRequiredCustomer: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.editMode) {
      const { id, name, monday, tuesday, thursday, friday } = this.props.data;
      this.setState({ 
        id, 
        name, 
        monday, 
        tuesday,
        thursday,
        friday,
        error_name: false,
        error_monday: false,
        error_tuesday: false,
        error_thursday: false,
        error_friday: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.name !== prevState.name) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.monday !== prevState.monday) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.tuesday !== prevState.tuesday) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.thursday !== prevState.thursday) {
      this.setState({ ready: this.isValid() });
    } else if (this.state.friday !== prevState.friday) {
      this.setState({ ready: this.isValid() });
    }

    if (this.props.message !== prevProps.message) {
      if (this.props.message.addRequiredCustomer) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          name: '',
          monday: 'No',
          tuesday: 'No',
          thursday: 'No',
          friday: 'No',
          error_name: true,
          error_monday: false,
          error_tuesday: false,
          error_thursday: false,
          error_friday: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      } else if (this.props.message.updateRequiredCustomer) {
        this.setState({
          ready: false,
          id: parseInt(Date.now().toString().slice(-8)),
          name: '',
          monday: 'No',
          tuesday: 'No',
          thursday: 'No',
          friday: 'No',
          error_name: true,
          error_monday: false,
          error_tuesday: false,
          error_thursday: false,
          error_friday: false,
        });
        this.props.handleClose();
        this.props.handleSubmit();
      }
    }
  }

  isValid = () => {
    return (!this.state.error_name && !this.state.error_monday && !this.state.error_tuesday && !this.state.error_thursday && !this.state.error_friday );
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    var inputVal = e.target.value;
    // No error if string is not empty or if it doesn't just contain whitespace
    if (inputVal && inputVal.replace(/\s/g, '').length > 0) {
      this.setState({ error_name: false });
    } else {
      this.setState({ error_name: true });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { id, name, monday, tuesday, thursday, friday } = this.state;
    const requiredCustomerEntry = { 
      id,
      name, 
      monday,
      tuesday,
      thursday,
      friday,
    };
    this.setState({
      error_name: false,
    });
    if (this.props.editMode) {
      this.props.updateRequiredCustomer(id, requiredCustomerEntry);  
    } else {
      this.props.addRequiredCustomer(requiredCustomerEntry);
    }  
  };

  handleAlertChange = (output) => this.setState({[output] : true});

  render() {
    const { name, monday, tuesday, thursday, friday } = this.state;

    const activeButton = (<Button variant='primary' type='submit' onClick={this.onSubmit}>Submit</Button>);
    const disabledButton = (<Button variant='secondary' disabled>Submit</Button>);

    return (
      <Modal backdrop='static' show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
          {this.props.editMode ? (<div className='d-flex align-items-center'><FaEdit/>&nbsp;Edit Required Customer</div>) : (<div className='d-flex align-items-center'><FaLeaf/>&nbsp;Add Required Customer</div>)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={3} md={2}>Customer</Form.Label>
              <Col sm={5}>
                <Form.Control 
                  type='text'
                  maxLength='100'
                  name='name'
                  onChange={this.onTextChange}
                  value={ name }
                  className={
                    this.state.error_name
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                />
              </Col>
            </Form.Group>

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Monday
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Yes'
                    label='Yes'
                    name='monday'
                    value='Yes'
                    checked={monday === 'Yes'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='No'
                    label='No'
                    name='monday'
                    value='No'
                    checked={monday === 'No'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Tuesday
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Yes'
                    label='Yes'
                    name='tuesday'
                    value='Yes'
                    checked={tuesday === 'Yes'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='No'
                    label='No'
                    name='tuesday'
                    value='No'
                    checked={tuesday === 'No'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>  

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Thursday
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Yes'
                    label='Yes'
                    name='thursday'
                    value='Yes'
                    checked={thursday === 'Yes'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='No'
                    label='No'
                    name='thursday'
                    value='No'
                    checked={thursday === 'No'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset> 

            <br />
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as='legend' column sm={2}>
                  Friday
                </Form.Label>
                <Col sm={10} style={{ marginTop: 'calc(.375rem + 1px)' }}>
                  <Form.Check
                    inline
                    type='radio'
                    id='Yes'
                    label='Yes'
                    name='friday'
                    value='Yes'
                    checked={friday === 'Yes'}
                    onChange={this.onChange}
                    required
                  />
                  <Form.Check
                    inline
                    type='radio'
                    id='No'
                    label='No'
                    name='friday'
                    value='No'
                    checked={friday === 'No'}
                    onChange={this.onChange}
                    required
                  />
                </Col>
              </Form.Group>
            </fieldset>  
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <RequiredCustomerFormAlert onAlertChange={this.handleAlertChange}/>
        {this.state.ready ? activeButton : disabledButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  required_customers: state.required_customers.required_customers,
  message: state.messages,
});

export default connect(mapStateToProps, { addRequiredCustomer, updateRequiredCustomer })(RequiredCustomerModal);