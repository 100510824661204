import React from 'react';
import { Button, Form } from 'react-bootstrap'; 
import { FaCheck } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';

function parseCsvToRowsAndColumn(csvText) {
  const rows = csvText.split('\n');
  if (!rows || rows.length === 0) {
    return [];
  }
  
  let matchCommas = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/g;
  let matchDoubleQuote = /"/g;
  
  let modifiedRows = rows.map(row => row.replace(matchCommas, ';'));
  modifiedRows = modifiedRows.map(row => row.replace(matchDoubleQuote, ''));
  return modifiedRows.map(row => row.split(';'));
}

function groupByCount(rowsWithColumns) {
  let newRows = rowsWithColumns;
  let indexReference = {};

  let currentGroup = '';
  let currentCount = 0;
  for (let i = 0; i < rowsWithColumns.length; i++) {
    if (rowsWithColumns[i][0] !== currentGroup) {
      if (currentCount) {
        indexReference[i - currentCount] = currentCount;
      }
      currentGroup = rowsWithColumns[i][0];
      currentCount = 1;
    } else {
      currentCount += 1;
      newRows[i] = newRows[i].slice(1);
    }
  }
  indexReference[rowsWithColumns.length - currentCount] = currentCount;
  return { indexReference, newRows };
}

const CsvToInteractiveTable = ({ data, hasHeader, tableClassName, tableRowClassName, tableColumnClassName, rowKey, colKey, formFunction, buttonFunction }) => {
  const rowsWithColumns = parseCsvToRowsAndColumn(data.trim());
  let headerRow = undefined;
  if (hasHeader) {
    headerRow = rowsWithColumns.splice(0, 1)[0];
  }

  const renderTableHeader = (row) => {
    if (row && row.map) {
      return (
        <thead>
        <tr>
          {row.map((column, i) => (
            <th key={`header-${i}`}>
              {column}
            </th>
          ))}
          <th key='final-count'>Final Count</th>
          <th key='check'>Confirm</th>
        </tr>
        </thead>
      );
    }
  };

  const groupByRenderTableBody = (rows) => {
    let { indexReference, newRows } = groupByCount(rows);
    
    if (newRows && newRows.map) {
      return (
        <tbody>
          {rows.map((row, rowIdx) => (
            <tr className={tableRowClassName} key={typeof(rowKey) === 'function' ? rowKey(row, rowIdx) : rowIdx} id={ `row-${rowIdx}` }>

              {row.map && row.map((column, colIdx) => (colIdx === 0) ? 
                (
                  <th 
                  className={tableColumnClassName} 
                  rowSpan={indexReference[rowIdx]}
                  valign='top'
                  key={typeof(rowKey) === 'function' ? colKey(row, colIdx, rowIdx) : column[colKey]}
                  >
                  {column}
                  </th>
                ) : 
                
                (
                  <td
                    className={tableColumnClassName}
                    key={typeof(rowKey) === 'function' ? colKey(row, colIdx, rowIdx) : column[colKey]}
                  >
                  {column}
                  </td>
                )
              )}
              
              <td>
                <Form>
                  <Form.Control 
                    type='text'
                    maxLength='2'
                    id={ `form-${rowIdx}` }
                    defaultValue={ Math.round(row[row.length-1]) === 0 ? '1' : Math.round(row[row.length-1]) }
                    onChange={ formFunction }
                    pattern='[0-9]'
                    required
                  />
                </Form>
              </td>
              <td>
                <Button variant='primary' type='submit' id={ `button-${rowIdx}` } tabIndex='-1' onClick={ buttonFunction }>
                  <div id={ `check-${rowIdx}` }>
                    <FaCheck/>
                  </div>
                  <div className='hidden' id={ `edit-${rowIdx}` }>
                    <MdEdit/>
                  </div>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      );
    }
  }

  return (
    <table className={`csv-html-table ${tableClassName}`}>
      {renderTableHeader(headerRow)}
      {groupByRenderTableBody(rowsWithColumns)}
    </table>
  );
};

CsvToInteractiveTable.defaultProps = {
  data: '',
  rowKey: (row, rowIdx) => `row-${rowIdx}`,
  colKey: (col, colIdx, rowIdx) => `col-${colIdx}`,
  hasHeader: true,
  tableClassName: '',
  tableRowClassName: '',
  tableColumnClassName: '',
  formFunction: '',
  buttonFunction: '',
};

export default CsvToInteractiveTable;