import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_CUSTOMERS, 
  ADD_CUSTOMER, 
  UPDATE_CUSTOMER, 
  DELETE_CUSTOMER 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'customers';

// GET CUSTOMERS
export const getCustomers = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD CUSTOMER
export const addCustomer = (customer) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, customer, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addCustomer: 'Customer Added' }));
      dispatch({
        type: ADD_CUSTOMER,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE CUSTOMER
export const updateCustomer = (id, customer) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, customer, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateCustomer: 'Customer Updated' }));
      dispatch({
        type: UPDATE_CUSTOMER,
        modified: id,
        payload: res.data,
      });

      if (id !== customer.name) {
        axios
          .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
          .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
      }
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));  
};

// DELETE CUSTOMER
export const deleteCustomer = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteCustomer: 'Customer Deleted' }));
      dispatch({
        type: DELETE_CUSTOMER,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};