import { 
    GET_REQUIRED_CUSTOMERS, 
    ADD_REQUIRED_CUSTOMER, 
    UPDATE_REQUIRED_CUSTOMER, 
    DELETE_REQUIRED_CUSTOMER, 
    CLEAR_ALL 
  } from '../actions/types';
  
  const initialState = {
    required_customers: [],
  };
  
  export default function requiredCustomersReducer(state = initialState, action) {
    switch (action.type) {
      case GET_REQUIRED_CUSTOMERS:
        return {
          ...state,
          required_customers: action.payload.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
        };
      case ADD_REQUIRED_CUSTOMER:
        return {
          ...state,
          required_customers: [...state.required_customers, action.payload].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
        };
      case UPDATE_REQUIRED_CUSTOMER:
        return {
          ...state,
          required_customers: [...state.required_customers.filter((customer) => customer.id !== action.modified), action.payload].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
        };
      case DELETE_REQUIRED_CUSTOMER:
        return {
          ...state,
          required_customers: state.required_customers.filter((customer) => customer.id !== action.payload),
        };
      case CLEAR_ALL:
        return {
          ...state,
          required_customers: [],
        };
      default:
        return state;
    }
  }