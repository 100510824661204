import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { loadUser } from './store/actions/auth';

import PrivateRoute from './components/common/PrivateRoute';
import TopNav from './components/elements/TopNav';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterRole from './pages/RegisterRole';
import Validation from './pages/Validation';
import Freight from './pages/Freight';
import Pallets from './pages/Pallets';
import Dispatch from './pages/Dispatch';
import Report from './pages/Report';
import CustomersEmployee from './pages/CustomersEmployee';
import CustomersManager from './pages/CustomersManager';
import CustomersAdmin from './pages/CustomersAdmin';

import ItemsEmployee from './pages/ItemsEmployee';
import ItemsManager from './pages/ItemsManager';
import ItemsAdmin from './pages/ItemsAdmin';

import PriceListManager from './pages/PriceListManager';
import CustomerShippingLocationsEmployee from './pages/CustomerShippingLocationsEmployee';
import CustomerShippingLocationsManager from './pages/CustomerShippingLocationsManager';
import CustomerShippingLocationsAdmin from './pages/CustomerShippingLocationsAdmin';

import Settings from './pages/Settings';
import SettingsRequiredCustomersManager from './pages/SettingsRequiredCustomersManager';
import SettingsRequiredCustomersAdmin from './pages/SettingsRequiredCustomersAdmin';
import SettingsIgnoreItemsManager from './pages/SettingsIgnoreItemsManager';
import SettingsIgnoreItemsAdmin from './pages/SettingsIgnoreItemsAdmin';

import SettingsCustomerRestrictedItemsManager from './pages/SettingsCustomerRestrictedItemsManager';
import SettingsCustomerRestrictedItemsAdmin from './pages/SettingsCustomerRestrictedItemsAdmin';

import SettingsRestrictedItemLocationsManager from './pages/SettingsRestrictedItemLocationsManager';
import SettingsRestrictedItemLocationsAdmin from './pages/SettingsRestrictedItemLocationsAdmin';
import NoMatch from './pages/NoMatch';

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      orders: [],
      navbarOpen: false,
      programs: {
        validation: {  
          id: 1,
          path: '/validation',
          name: 'Validate Invoices',
          short: 'Validation',
          description: 'Catch invoice item duplicates, price mismatches, restricted locations/items, and missing required customers using a Sales Report.',
          icon: '/icons/file.png',
          role_access: [1,2,9],
        },
        freight: {  
          id: 2,
          path: '/freight',
          name: 'Freight Calculation',
          short: 'Freight',
          description: "Get the daily freight cost for Miami, Miami 2, LF Delivery, and Orlando using a Sales Report.",
          icon: '/icons/containers.png',
          role_access: [1,2,9],
        },
        pallets: {  
          id: 3,
          path: '/pallets',
          name: 'Pallet Estimates',
          short: 'Pallets',
          description: "Estimate pallets with Small, Big, and Tofu boxes using a Sales Report.",
          icon: '/icons/pallet.png',
          role_access: [1,2,9],
        },
        dispatch: {  
          id: 4,
          path: '/dispatch',
          name: 'Dispatch Center',
          short: 'Dispatch',
          description: "Get all the recently submitted Pallet Estimates reports.",
          icon: '/icons/truck.png',
          role_access: [1,2,9],
        },
        reports: {  
          id: 5,
          path: '/reports',
          name: 'Latest Reports',
          short: 'Reports',
          description: 'Get the lastest reports from Validate Invoices, Freight Calculation, and Price List.',
          icon: '/icons/recent-file.png',
          role_access: [1,2,9],
        }, 
        customers_employee: {
          id: 6,
          path: '/customers',
          name: 'Customers',
          short: 'Customers',
          description: 'View a Customer\'s name, phone, fax, type, and price level.',
          icon: '/icons/contact.png',
          role_access: [1,9],
        }, 
        customers_manager: {
          id: 7,
          path: '/customers/edit',
          name: 'Customers',
          short: 'Customers',
          description: 'View, edit, or add a Customer\'s name, phone, fax, type, and price level.',
          icon: '/icons/contact-update.png',
          role_access: [2,9],
        }, 
        customers_admin: {
          id: 8,
          path: '/customers/upload',
          name: 'Upload Customers',
          short: 'Upload Customers',
          description: 'Upload Customer info using an exported Quickbooks CSV.',
          icon: '/icons/contact-upload.png',
          role_access: [9],
        }, 
        items_employee: {
          id: 9,
          path: '/items',
          name: 'Items',
          short: 'Items',
          description: 'View a Item\'s code, description, type, prices, box details,and freight costs.',
          icon: '/icons/plant.png',
          role_access: [1,9],
        },
        items_manager: {
          id: 10,
          path: '/items/edit',
          name: 'Items',
          short: 'Items',
          description: 'View, edit, or add a Item\'s code, description, type, prices, box details,and freight costs.',
          icon: '/icons/plant-update.png',
          role_access: [2,9],
        },  
        items_admin: {
          id: 11,
          path: '/items/upload',
          name: 'Upload Items',
          short: 'Upload Items',
          description: 'Upload Item info using an exported Quickbooks CSV.',
          icon: '/icons/plant-upload.png',
          role_access: [9],
        },
        price_list_manager: {
          id: 12,
          path: '/price_list',
          name: 'Update Price List',
          short: 'Price List',
          description: 'Update Price List for items using an exported Quickbooks CSV.',
          icon: '/icons/price-tag.png',
          role_access: [2,9],
        },
        customer_shipping_locations_employee: {
          id: 13,
          path: '/customer_shipping_locations',
          name: 'Customer Shipping Locations',
          short: 'Shipping',
          description: 'View Customer\'s Ship To location.',
          icon: '/icons/shipping-location.png',
          role_access: [1,9],
        }, 
        customer_shipping_locations_manager: {
          id: 14,
          path: '/customer_shipping_locations/edit',
          name: 'Customer Shipping Locations',
          short: 'Shipping',
          description: 'View, edit, or add Customer\'s Ship To location.',
          icon: '/icons/shipping-location-update.png',
          role_access: [2,9],
        }, 
        customer_shipping_locations_admin: {
          id: 15,
          path: '/customer_shipping_locations/upload',
          name: 'Upload Customer Shipping Locations',
          short: 'Upload Shipping',
          description: 'Upload Customer Shipping Location info using an exported Quickbooks CSV.',
          icon: '/icons/shipping-location-upload.png',
          role_access: [9],
        }, 
        settings: {
          id: 16,
          path: '/settings',
          name: 'Settings',
          short: 'Settings',
          description: 'Managers can control required customers, ignore items, customer restricted items, and restricted item locations.',
          icon: '/icons/gear.png',
          role_access: [2,9],
        },
      },
      settings: {
        required_customers_manager: {
          id: 1,
          path: '/settings/required_customers',
          name: 'Required Customers',
          short: 'Required Customers',
          description: 'View, edit, or add a Required Customer\'s for Mon/Tues/Thurs/Fri.',
          icon: '/icons/contact-required.png',
          role_access: [2,9],
        },
        required_customers_admin: {
          id: 2,
          path: '/settings/required_customers/upload',
          name: 'Upload Required Customers',
          short: 'Upload Required Customers',
          description: 'Upload Required Customer info using an custom CSV.',
          icon: '/icons/contact-required.png',
          role_access: [9],
        },
        ignore_items_manager: {
          id: 3,
          path: '/settings/ignore_items',
          name: 'Ignore Items',
          short: 'Ignore Items',
          description: 'View, edit, or add a Ignore Item\'s string, type, and field.',
          icon: '/icons/plant-ignore.png',
          role_access: [2,9],
        },
        ignore_items_admin: {
          id: 4,
          path: '/settings/ignore_items/upload',
          name: 'Upload Ignore Items',
          short: 'Upload Ignore Items',
          description: 'Upload Ignored Items info using an custom CSV.',
          icon: '/icons/plant-ignore.png',
          role_access: [9],
        },
        customer_restricted_items_manager: {
          id: 5,
          path: '/settings/customer_restricted_items',
          name: 'Customer Restricted Items',
          short: 'Customer Restricted Items',
          description: 'View, edit, or add a Customer Restricted Item\'s customer name and a list of item codes.',
          icon: '/icons/customer-restricted-item.png',
          role_access: [2,9],
        },
        customer_restricted_items_admin: {
          id: 6,
          path: '/settings/customer_restricted_items/upload',
          name: 'Upload Customer Restricted Items',
          short: 'Upload Customer Restricted Items',
          description: 'Upload Customer Restricted Items info using an custom CSV.',
          icon: '/icons/customer-restricted-item.png',
          role_access: [9],
        },
        restricted_item_locations_manager: {
          id: 7,
          path: '/settings/restricted_item_locations',
          name: 'Restricted Item Locations',
          short: 'Restricted Item Locations',
          description: 'View, edit, or add a Restricted Item Location\'s item code and a list of locations.',
          icon: '/icons/restricted-item-location.png',
          role_access: [2,9],
        },
        restricted_item_locations_admin: {
          id: 8,
          path: '/settings/restricted_item_locations/upload',
          name: 'Upload Restricted Item Locations',
          short: 'Upload Restricted Item Locations',
          description: 'Upload Restricted Item Locations info using an custom CSV.',
          icon: '/icons/restricted-item-location.png',
          role_access: [9],
        },
      },
    };
  }

  async componentDidMount() {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL);
      store.dispatch(loadUser());
      const orders = await res.json();
      this.setState({ orders });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (    
      <Provider store={store}>
        <Router>
          <TopNav programs={this.state.programs}/>
            <Switch>
              <PrivateRoute
                exact path='/'
                role_access={[1,2,9]}
                component={() => <Dashboard programs={this.state.programs} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.validation.path} 
                role_access={this.state.programs.validation.role_access}
                component={() => <Validation programs={this.state.programs.validation} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.freight.path}
                role_access={this.state.programs.freight.role_access}
                component={() => <Freight programs={this.state.programs.freight} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.pallets.path}
                role_access={this.state.programs.pallets.role_access}
                component={() => <Pallets programs={this.state.programs.pallets} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.dispatch.path}
                role_access={this.state.programs.dispatch.role_access}
                component={() => <Dispatch programs={this.state.programs.dispatch} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.reports.path} 
                role_access={this.state.programs.reports.role_access}
                component={() => <Report programs={this.state.programs.reports} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.customers_employee.path} 
                role_access={this.state.programs.customers_employee.role_access}
                component={() => <CustomersEmployee programs={this.state.programs.customers_employee} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.items_employee.path} 
                role_access={this.state.programs.items_employee.role_access}
                component={() => <ItemsEmployee programs={this.state.programs.items_employee} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.customers_manager.path} 
                role_access={this.state.programs.customers_manager.role_access}
                component={() => <CustomersManager programs={this.state.programs.customers_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.items_manager.path} 
                role_access={this.state.programs.items_manager.role_access}
                component={() => <ItemsManager programs={this.state.programs.items_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.price_list_manager.path} 
                role_access={this.state.programs.price_list_manager.role_access}
                component={() => <PriceListManager programs={this.state.programs.price_list_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.customers_admin.path} 
                role_access={this.state.programs.customers_admin.role_access}
                component={() => <CustomersAdmin programs={this.state.programs.customers_admin} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.items_admin.path} 
                role_access={this.state.programs.items_admin.role_access}
                component={() => <ItemsAdmin programs={this.state.programs.items_admin} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.customer_shipping_locations_employee.path} 
                role_access={this.state.programs.customer_shipping_locations_employee.role_access}
                component={() => <CustomerShippingLocationsEmployee programs={this.state.programs.customer_shipping_locations_employee} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.customer_shipping_locations_manager.path} 
                role_access={this.state.programs.customer_shipping_locations_manager.role_access}
                component={() => <CustomerShippingLocationsManager programs={this.state.programs.customer_shipping_locations_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.customer_shipping_locations_admin.path} 
                role_access={this.state.programs.customer_shipping_locations_admin.role_access}
                component={() => <CustomerShippingLocationsAdmin programs={this.state.programs.customer_shipping_locations_admin} />} 
              />
              <PrivateRoute 
                exact path={this.state.programs.settings.path} 
                role_access={this.state.programs.settings.role_access}
                component={() => <Settings programs={this.state.settings} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.required_customers_manager.path} 
                role_access={this.state.settings.required_customers_manager.role_access}
                component={() => <SettingsRequiredCustomersManager programs={this.state.settings.required_customers_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.required_customers_admin.path} 
                role_access={this.state.settings.required_customers_admin.role_access}
                component={() => <SettingsRequiredCustomersAdmin programs={this.state.settings.required_customers_admin} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.ignore_items_manager.path} 
                role_access={this.state.settings.ignore_items_manager.role_access}
                component={() => <SettingsIgnoreItemsManager programs={this.state.settings.ignore_items_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.ignore_items_admin.path} 
                role_access={this.state.settings.ignore_items_admin.role_access}
                component={() => <SettingsIgnoreItemsAdmin programs={this.state.settings.ignore_items_admin} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.customer_restricted_items_manager.path} 
                role_access={this.state.settings.customer_restricted_items_manager.role_access}
                component={() => <SettingsCustomerRestrictedItemsManager programs={this.state.settings.customer_restricted_items_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.customer_restricted_items_admin.path} 
                role_access={this.state.settings.customer_restricted_items_admin.role_access}
                component={() => <SettingsCustomerRestrictedItemsAdmin programs={this.state.settings.customer_restricted_items_admin} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.restricted_item_locations_manager.path} 
                role_access={this.state.settings.restricted_item_locations_manager.role_access}
                component={() => <SettingsRestrictedItemLocationsManager programs={this.state.settings.restricted_item_locations_manager} />} 
              />
              <PrivateRoute 
                exact path={this.state.settings.restricted_item_locations_admin.path} 
                role_access={this.state.settings.restricted_item_locations_admin.role_access}
                component={() => <SettingsRestrictedItemLocationsAdmin programs={this.state.settings.restricted_item_locations_admin} />} 
              />
              <Route exact path='/register' 
                component={Register} 
              />
              <Route exact path='/register/employee' 
                component={() => <RegisterRole employeeMode={true}/>} 
              />
              <Route exact path='/register/manager' 
                component={() => <RegisterRole employeeMode={false}/>} 
              />
              <Route exact path='/login' 
                component={Login} 
              />
              <Route component={NoMatch} />
            </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;