import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_CUSTOMER_SHIPPING_LOCATIONS, 
  ADD_CUSTOMER_SHIPPING_LOCATION, 
  UPDATE_CUSTOMER_SHIPPING_LOCATION, 
  DELETE_CUSTOMER_SHIPPING_LOCATION 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'customer_shipping_locations';

// GET CUSTOMER SHIPPING LOCATIONS
export const getCustomerShippingLocations = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CUSTOMER_SHIPPING_LOCATIONS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD CUSTOMER SHIPPING LOCATION
export const addCustomerShippingLocation = (customer_shipping_location) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, customer_shipping_location, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addCustomerShippingLocation: 'Customer Shipping Location Added' }));
      dispatch({
        type: ADD_CUSTOMER_SHIPPING_LOCATION,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE CUSTOMER SHIPPING LOCATION
export const updateCustomerShippingLocation = (id, customer_shipping_location) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, customer_shipping_location, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateCustomerShippingLocation: 'Customer Shipping Location Updated' }));
      dispatch({
        type: UPDATE_CUSTOMER_SHIPPING_LOCATION,
        modified: id,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));  
};

// DELETE CUSTOMER SHIPPING LOCATION
export const deleteCustomerShippingLocation = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteCustomerShippingLocation: 'Customer Shipping Location Deleted' }));
      dispatch({
        type: DELETE_CUSTOMER_SHIPPING_LOCATION,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};