import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'; 
import { FiAlertTriangle } from 'react-icons/fi';

class WarningModal extends Component {
  render() {
    return (
      <Modal show={ this.props.show } onHide={ this.props.handleClose } size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='d-flex align-items-center'>
              <FiAlertTriangle/>&nbsp;Missing {this.props.day} Orders
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The sales report you imported is missing the orders for <strong>{this.props.day}</strong>.<br />
            You should double check the date range of your Quickbooks export.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ this.props.handleClose }>Ok, I got it.</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WarningModal;