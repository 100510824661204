import { 
  GET_ITEMS, 
  ADD_ITEM, 
  UPDATE_ITEM, 
  DELETE_ITEM, 
  CLEAR_ALL 
} from '../actions/types';

const initialState = {
  items: [],
};

export default function itemsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ITEMS:
      return {
        ...state,
        items: action.payload.sort((a, b) => (a.item.toUpperCase() > b.item.toUpperCase()) ? 1 : -1),
      };
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload].sort((a, b) => (a.item.toUpperCase() > b.item.toUpperCase()) ? 1 : -1),
      };
    case UPDATE_ITEM:
      return {
        ...state,
        items: [...state.items.filter((item) => item.item !== action.modified), action.payload].sort((a, b) => (a.item.toUpperCase() > b.item.toUpperCase()) ? 1 : -1),
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.item !== action.payload),
      };
    case CLEAR_ALL:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
}