import { 
    GET_IGNORE_ITEMS, 
    ADD_IGNORE_ITEM, 
    UPDATE_IGNORE_ITEM, 
    DELETE_IGNORE_ITEM, 
    CLEAR_ALL 
  } from '../actions/types';
  
  const initialState = {
    ignore_items: [],
  };
  
  export default function ignoreItemsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_IGNORE_ITEMS:
        return {
          ...state,
          ignore_items: action.payload.sort((a, b) => (a.match_string.toUpperCase() > b.match_string.toUpperCase()) ? 1 : -1),
        };
      case ADD_IGNORE_ITEM:
        return {
          ...state,
          ignore_items: [...state.ignore_items, action.payload].sort((a, b) => (a.match_string.toUpperCase() > b.match_string.toUpperCase()) ? 1 : -1),
        };
      case UPDATE_IGNORE_ITEM:
        return {
          ...state,
          ignore_items: [...state.ignore_items.filter((item) => item.id !== action.modified), action.payload].sort((a, b) => (a.match_string.toUpperCase() > b.match_string.toUpperCase()) ? 1 : -1),
        };
      case DELETE_IGNORE_ITEM:
        return {
          ...state,
          ignore_items: state.ignore_items.filter((item) => item.id !== action.payload),
        };
      case CLEAR_ALL:
        return {
          ...state,
          ignore_items: [],
        };
      default:
        return state;
    }
  }