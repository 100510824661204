import React, { Component } from 'react'

class DragAndDrop extends Component {
  constructor (props) {
    super();
    
    this.state = {
      drag: false
    };
  }

  dropRef = React.createRef();

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true});
    }
  }

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({drag: false});
    }
  }
  
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({drag: false});
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      this.dragCounter = 0;
    }
  }

  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <div style={{ position: 'relative', flex: '0 0 100%' }} ref={this.dropRef}>
        {this.state.drag &&
          <div id='drag-overlay'>
            <div id='drag-overlay-text'>
              <div>Drop file here</div>
            </div>
          </div>
        }
        {this.props.children}
      </div>
    )
  }
}

export default DragAndDrop;