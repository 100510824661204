import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_RESTRICTED_ITEM_LOCATIONS, 
  ADD_RESTRICTED_ITEM_LOCATION, 
  UPDATE_RESTRICTED_ITEM_LOCATION, 
  DELETE_RESTRICTED_ITEM_LOCATION 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'restricted_item_locations';

// GET RESTRICTED ITEM LOCATION
export const getRestrictedItemLocations = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_RESTRICTED_ITEM_LOCATIONS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD RESTRICTED ITEM LOCATION
export const addRestrictedItemLocation = (restricted_item_location) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, restricted_item_location, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addRestrictedItemLocation: 'Restricted Item Location Added' }));
      dispatch({
        type: ADD_RESTRICTED_ITEM_LOCATION,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE RESTRICTED ITEM LOCATION
export const updateRestrictedItemLocation = (id, restricted_item_location) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, restricted_item_location, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateRestrictedItemLocation: 'Restricted Item Location Updated' }));
      dispatch({
        type: UPDATE_RESTRICTED_ITEM_LOCATION,
        modified: id,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// DELETE RESTRICTED ITEM LOCATION
export const deleteRestrictedItemLocation = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteRestrictedItemLocation: 'Restricted Item Location Deleted' }));
      dispatch({
        type: DELETE_RESTRICTED_ITEM_LOCATION,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};