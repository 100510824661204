import { 
  GET_CUSTOMERS, 
  ADD_CUSTOMER, 
  UPDATE_CUSTOMER, 
  DELETE_CUSTOMER, 
  CLEAR_ALL 
} from '../actions/types';

const initialState = {
  customers: [],
};

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.payload].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers.filter((customer) => customer.name !== action.modified), action.payload].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1),
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter((customer) => customer.name !== action.payload),
      };
    case CLEAR_ALL:
      return {
        ...state,
        customers: [],
      };
    default:
      return state;
  }
}