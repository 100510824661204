import axios from 'axios';
import { returnErrors, createMessage } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_IGNORE_ITEMS, 
  ADD_IGNORE_ITEM, 
  UPDATE_IGNORE_ITEM, 
  DELETE_IGNORE_ITEM 
} from './types';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const SUB_API_URL = 'ignore_items';

// GET IGNORE ITEMS
export const getIgnoreItems = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/${SUB_API_URL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_IGNORE_ITEMS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// ADD IGNORE ITEM
export const addIgnoreItem = (ignore_item) => (dispatch, getState) => {
  axios
    .post(`${API_URL}/${SUB_API_URL}/`, ignore_item, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addIgnoreItem: 'Ignore Item Added' }));
      dispatch({
        type: ADD_IGNORE_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// UPDATE IGNORE ITEM
export const updateIgnoreItem = (id, ignore_item) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .put(`${API_URL}/${SUB_API_URL}/${idURL}/`, ignore_item, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateIgnoreItem: 'Ignore Item Updated' }));
      dispatch({
        type: UPDATE_IGNORE_ITEM,
        modified: id,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// DELETE IGNORE ITEM
export const deleteIgnoreItem = (id) => (dispatch, getState) => {
  let idURL = encodeURIComponent(id);
  axios
    .delete(`${API_URL}/${SUB_API_URL}/${idURL}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteIgnoreItem: 'Ignore Item Deleted' }));
      dispatch({
        type: DELETE_IGNORE_ITEM,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};