import React, { Component } from 'react';
import { Table } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import { getItems } from '../store/actions/items';
import { FaSearch } from 'react-icons/fa';

class ItemsEmployee extends Component {
  state = {
    search: '',
  }

  static propTypes = {
    items: PropTypes.array.isRequired,
    getItems: PropTypes.func.isRequired,
  }
  
  componentDidMount() {
    this.props.getItems();
  }

  filterItems = (e) => {
    let search = e.target.value;
    this.setState({ search });
  }

  render() {
    let filteredItems = this.props.items;

    if (this.state.search.length > 0) {
      filteredItems = filteredItems.filter(c=> c.item.toUpperCase().includes(this.state.search.toUpperCase()) || c.description.toUpperCase().includes(this.state.search.toUpperCase()));
    }

    return (
      <div className='page'>
        <Title program={this.props.programs} />
        <br />
        <div className='input-group'>
          <input className='form-control py-2 border-right-0 border' type='text' onChange={this.filterItems} placeholder='Search for items by name or description...' title='Type in an item' />
          <span className='input-group-append'>
            <div className='input-group-text bg-transparent'><FaSearch/></div>
          </span>
        </div>
        <div className='flex-grow-1 mt-4'>
          <Table striped bordered hover size='sm' responsive='lg'>
            <thead>
              <tr>
                <th>Item</th>
                <th>Description</th>
                <th>Type</th>
                <th>Price</th>
                <th>MIA Price</th>
                <th>ORL Price</th>
                <th>Boxes / Layer</th>
                <th>Layers / Pallet</th>
                <th>Boxes / Pallet</th>
                <th>MIA Freight</th>
                <th>ORL Freight</th>
                <th>Box Type</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item) => (
                <tr key={item.item}>
                  <td>{item.item}</td>
                  <td>{item.description}</td>
                  <td>{item.item_type}</td>
                  <td>{item.price}</td>
                  <td>{item.mia_price}</td>
                  <td>{item.orl_price}</td>
                  <td>{item.boxes_layer}</td>
                  <td>{item.layers_pallet}</td>
                  <td>{item.boxes_pallet}</td>
                  <td>{item.mia_freight}</td>
                  <td>{item.orl_freight}</td>
                  <td>{item.box_type}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.items.items,
});

export default connect(mapStateToProps, { getItems })(ItemsEmployee);