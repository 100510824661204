import axios from 'axios';
import { returnErrors } from './messages';
import { tokenConfig } from './auth';
import { 
  GET_REPORTS, 
  GET_OUTPUT,
  CLEAR_ALL
} from './types';

const MAIN_URL = process.env.REACT_APP_API_URL;
const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export const getLatestReport = (scriptType, data) => {
  var latestReportIndex = null;
  for (var i = data.length - 1; i >= 0; --i) {
    if (data[i].script === scriptType && !data[i].result.includes('INVALID') && !data[i].result.includes('ERROR')) {
      latestReportIndex = i;
      break;
    }
  }

  if (latestReportIndex === null) {
    let noReport = {
      date: '',
      script: scriptType,
      owner: '',
      result: '',
      input: '',
      report: 'NO REPORTS',
    };
    return noReport;
  }

  let report = '';
  if (scriptType === 'VALIDATION - DUPS') {
    report = 'NO DUPS';
  } else if (scriptType === 'VALIDATION - MISMATCHES') {
    report = 'NO MISMATCHES';
  } else if (scriptType === 'PALLETS') {
    report = 'NO PALLETS';
  } else if (scriptType === 'PRICE LIST') {
    report = 'NO PRICE LIST';
  } else if (scriptType.includes('FREIGHT')){
    report = 'NO FREIGHT';
  }

  if (data[latestReportIndex].result.includes('DETECTED') || data[latestReportIndex].result.includes('CALCULATED') || data[latestReportIndex].result.includes('UPDATED')) {
    let reportName = data[latestReportIndex].output.split('/media/')[1];
    report = `${MAIN_URL}/media/${reportName}`;
  }
  let inputName = data[latestReportIndex].file.split('/media/')[1];
  
  let latestReport = {
    date: data[latestReportIndex].date,
    script: data[latestReportIndex].script,
    owner: data[latestReportIndex].owner,
    result: data[latestReportIndex].result,
    input: `${MAIN_URL}/media/${inputName}`,
    report: report,
  };
  return latestReport;
};

// GET REPORT
export const getReports = (scriptType) => (dispatch, getState) => {
  axios
    .get(`${API_URL}/logs/`, tokenConfig(getState))
    .then((res) => {
      let latest = getLatestReport(scriptType, res.data);
      dispatch({
        type: CLEAR_ALL,
      });
      dispatch({
        type: GET_REPORTS,
        payload: latest,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// GET OUTPUT
export const getOutput = (fileURL) => (dispatch, getState) => {
  axios
    .get(fileURL, tokenConfig(getState))
    .then((res) => {
      let table_text = res.data.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '');
      let table_text_remove_inner_commas = table_text.replace(/,(?=[^"]*"[^"]*(?:"[^"]*"[^"]*)*$)/gm, '');
      let table_text_cleaned = table_text_remove_inner_commas.replace(/"/gm, '');
      dispatch({
        type: GET_OUTPUT,
        payload: table_text_cleaned,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
