import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'; 
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, auth, role_access, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!auth.isAuthenticated && !auth.isLoading) {
        return <Redirect to='/login' />;
      } else if (auth.isLoading) {
        return (
          <div style={{ postion: 'relative', textAlign: 'center' }}>
            <div className='center-page'>
              <h2>Welcome back!</h2>
              <Spinner animation='border' role='status' variant='info' className='d-block m-auto' 
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className='sr-only'>Loading...</span>
              </Spinner>
              <br />
              <h3>Please wait. I'm logging you back in...</h3>
            </div>
          </div>
        );
      } else if (auth.isAuthenticated && (role_access.indexOf(auth.user.role) >= 0)) {
        return <Component {...props} />;
      } else {
        return <Redirect to='/login' />;
      }
    }}
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);