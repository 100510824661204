import React, { Component } from 'react';
import { Table } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from '../components/elements/Title';
import { getCustomerShippingLocations } from '../store/actions/customer_shipping_locations';
import { FaSearch } from 'react-icons/fa';

class CustomerShippingLocationsEmployee extends Component {
  state = {
    search: '',
  }

  static propTypes = {
    customer_shipping_locations: PropTypes.array.isRequired,
    getCustomerShippingLocations: PropTypes.func.isRequired,
  }
  
  componentDidMount() {
    this.props.getCustomerShippingLocations();
  }

  filterCustomerShippingLocations = (e) => {
    let search = e.target.value;
    this.setState({ search });
  }

  render() {
    let filteredCustomerShippingLocations = this.props.customer_shipping_locations;

    if (this.state.search.length > 0) {
      filteredCustomerShippingLocations = filteredCustomerShippingLocations.filter(c=> c.name.toUpperCase().includes(this.state.search.toUpperCase()));
    }

    return (
      <div className='page'>
        <Title program={this.props.programs} />
        <br />
        <div className='input-group'>
          <input className='form-control py-2 border-right-0 border' type='text' onChange={this.filterCustomerShippingLocations} placeholder='Search for customer shipping locations by name...' title='Type in a customer shipping location' />
          <span className='input-group-append'>
            <div className='input-group-text bg-transparent'><FaSearch/></div>
          </span>
        </div>
        <div className='flex-grow-1 mt-4'>
          <Table striped bordered hover size='sm'responsive='md'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Ship To</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomerShippingLocations.map((customer_shipping_location) => (
                <tr key={customer_shipping_location.id}>
                  <td>{customer_shipping_location.name}</td>
                  <td>{customer_shipping_location.ship_to}</td>
                  <td>{customer_shipping_location.location}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer_shipping_locations: state.customer_shipping_locations.customer_shipping_locations,
});

export default connect(mapStateToProps, { getCustomerShippingLocations })(CustomerShippingLocationsEmployee);