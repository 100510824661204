import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Register extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to='/' />;
    }
    return (
      <div id='login-card' className='col-md-3 m-auto'>
        <div id='login-card-body' className='card card-body mt-5'>
          <h2 className='text-center'>Register</h2>
          <p>
            Select below the type of account you want to create.
          </p>
          <div>
            <Link to='/register/employee'>
              <Button className='p-3 mb-4' block>
                Employee
              </Button>
            </Link>
          </div>
          <br />
          <div>
            <Link to='/register/manager'>
              <Button className='p-3' block>
                Manager
              </Button>
            </Link>
          </div>
          <p className='mt-4'>
            Already have an account? <Link to='/login'>Login</Link>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Register);