import React, { Component, Fragment } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/auth';

class TopNav extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  filterPrograms = (programs, role) => {
    let displayPrograms = [];
    for (var key in programs) {
      if (programs[key]['role_access'].indexOf(role) >= 0) {
        displayPrograms.push(programs[key])
      }
    }
    return displayPrograms;
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;

    if (user) {
      var filteredPrograms = this.filterPrograms(this.props.programs, user.role);
    }

    const authLinks = (
      <Fragment>
        <span className='navbar-text mr-3 welcome-message'>
          {user ? `Welcome ${user.full_name}` : ''}!
        </span>
        <li className='nav-item d-flex'>
          <Button onClick={ this.props.logout } id='logout-button' variant='outline' size='sm'>
            Logout
          </Button>
        </li>
      </Fragment>
    );
    
    const guestLinks = (
      <>
        <Nav.Item key='/register' >
          <Nav.Link 
            className='mr-3'
            key='/register'
            eventKey='/register'
            as={Link} 
            to='/register'
          >
            Register
          </Nav.Link>
        </Nav.Item>
        <Nav.Item key='/login' >
          <Nav.Link
            key='/login'
            eventKey='/login'
            as={Link} 
            to='/login'
          >
            Login
          </Nav.Link>
        </Nav.Item>
      </>
    );    

    return (
      <Navbar collapseOnSelect bg='dark' variant='dark' expand='xl'>
        <Link to='/'>
          <Navbar.Brand>
            <img
              src='/logo.svg'
              width='30'
              height='30'
              className='d-inline-block align-top'
              alt='Liam'
            />{' '}
            Liam
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
          {user ? filteredPrograms.map(p => (
            <Nav.Item key={ p.path } >
              <Nav.Link 
                className={this.props.location.pathname === p.path ? 'active' : ''}
                key={ p.path } 
                eventKey={ p.path } 
                as={Link} 
                to={ p.path }
              >
                { p.short }
              </Nav.Link>
            </Nav.Item>
          )) : ''
          }
          </Nav>
          <div className='navbar-nav ml-auto mt-2 mt-lg-0 flex-row float-right'>
            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(TopNav));