import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class NoMatch extends Component {
  render() {
    return (
      <Fragment>
        <div style={{ postion: 'relative', textAlign: 'center' }}>
          <img src='/icons/beach.jpg' alt='404 page not found' style={{ width: '100%' }} />
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
            <h1 className='font-weight-bold'>404 ERROR<br/></h1>
            <h3>Requested page was not found</h3>
            <br/>
            <Link to='/'><Button>Return home</Button></Link>
          </div>
        </div> 
      </Fragment>
    );
  }
}

export default NoMatch;